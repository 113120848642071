import React, { FC, useCallback, useContext, useEffect, useState } from "react";

// Hooks
import {useDateTimeFormat} from "../hooks/useDateTimeFormat";

// Components
import { Snackbar } from "@material-ui/core";
import { TranslationContext } from "../translationcontext";
import { BookingDetailsModal } from "../components/bookingdetails_modal";
import { Loader } from "@mantine/core";

// API
import { GetAllFutureReservations, GetReservations } from "../api";

// Lib
import {
  currencyFormatter,
  dotNetToJSDate,
  getDaysBetweenDates,
  getDaysInOneMonth,
  useIsMounted,
} from "../helpers";

// Types
import { BookingsProps } from "./interfaces";
import { OwnerPortalReservation } from "../generated_api";
import { UserContext } from "../usercontext";

const vertical = "bottom";
const horizontal = "center";

const imageUrl =
  process.env.NODE_ENV === "development"
    ? "http://images.local/img/"
    : "https://images.bookvisit.com/img/";

export const Bookings: FC<BookingsProps> = ({
  selectedObject,
  onSelectedObjectChanged,
  ownersObjects,
}) => {
  const translation = useContext(TranslationContext);
  const [drawerState, setDrawerState] = useState<{
    show: boolean;
    booking: OwnerPortalReservation | undefined;
  }>({
    show: false,
    booking: undefined,
  });
  let { hidePayDetails } = useContext(UserContext);

  const [snackbarState, setSnackbarState] = useState({
    show: false,
    text: "",
    color: "seagreen",
  });
  const [state, setState] = useState<OwnerPortalReservation[]>([]);
  const [selectedMonth, setSelectedMonth] = useState(
    `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
      .toString()
      .padStart(2, "0")}`
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isCustomMonthSelected, setIsCustomMonthSelected] = useState(false);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState("all");
  const [days, setDays] = useState<Date[]>([]);

  const {languageCulture, dateTimeFormatter} = useDateTimeFormat()

  const isMounted = useIsMounted();

  const fetchAllFutureReservations = useCallback(async () => {
    if (selectedObject === undefined) {
      return;
    }
    setIsLoading(true);

    let fetchResult = await GetAllFutureReservations(selectedObject!, true);
    let result = fetchResult?.ownerPortalReservations;

    if (!isMounted.current) {
      return;
    }
    if (result === undefined) {
      return;
    }

    const sortedReservations =
      result.length === 0
        ? []
        : result.sort(
            (a, b) =>
              new Date(a.arrival!).getTime() - new Date(b.arrival!).getTime()
          );

    sortedReservations.forEach((x) => {
      x.arrival = dotNetToJSDate(x.arrival!);
      x.departure = dotNetToJSDate(x.departure!);
    });

    if (selectedObject) {
      setState(sortedReservations.filter(x => x.roomTypeId === selectedObject || x.roomUnitId === selectedObject))
    }

    setIsLoading(false);
  }, [isMounted, selectedObject]);

  const fetchReservations = useCallback(
    async (startDate: Date, endDate: Date) => {
      setIsLoading(true);
      let result = (
        await GetReservations(
          startDate.toLocaleDateString("sv-SE"),
          endDate.toLocaleDateString("sv-SE"),
          true,
          true
        )
      ).ownerPortalReservations;
      if (!isMounted.current) {
        return;
      }
      if (result === undefined) {
        return;
      }

      const sortedReservations =
        result.length === 0
          ? []
          : result.sort(
              (a, b) =>
                new Date(a.arrival!).getTime() - new Date(b.arrival!).getTime()
            );

      sortedReservations.forEach((x) => {
        x.arrival = dotNetToJSDate(x.arrival!);
        x.departure = dotNetToJSDate(x.departure!);
      });

      if (selectedObject) {
        setState(
          sortedReservations.filter(
            (x) =>
              x.roomTypeId === selectedObject || x.roomUnitId === selectedObject
          )
        );
      }
      setIsLoading(false);
    },
    [isMounted, selectedObject]
  );

  const fetchReservationsForMonth = useCallback(
    (month: string) => {
      setSelectedMonth(month);
      let selected = new Date(month);
      let newDays = getDaysInOneMonth(
        selected.getMonth(),
        selected.getFullYear()
      );

      setDays(newDays);
      fetchReservations(newDays[0], newDays[newDays.length - 1]);
    },
    [fetchReservations]
  );

  const fetchReservationsForDateRange = useCallback(
    (value: string) => {
      setSelectedTimePeriod(value);
      let today = new Date();
      let inThreeMonths = new Date(
        new Date().setMonth(new Date().getMonth() + 3)
      );
      let daysInCurrentMonth = getDaysInOneMonth(
        new Date().getMonth(),
        new Date().getFullYear()
      );
      let newDays: Date[] = [];
      if (value === "all") {
        setDays(newDays);
        fetchAllFutureReservations();
      } else if (value === "thisMonth") {
        setDays(daysInCurrentMonth);
        fetchReservations(
          daysInCurrentMonth[0],
          daysInCurrentMonth[daysInCurrentMonth.length - 1]
        );
      } else if (value === "nextMonth") {
        let nextMonth = new Date(
          new Date().setMonth(new Date().getMonth() + 1)
        );
        newDays = getDaysInOneMonth(
          nextMonth.getMonth(),
          nextMonth.getFullYear()
        );
        setDays(newDays);
        fetchReservations(newDays[0], newDays[newDays.length - 1]);
      } else if (value === "comingThreeMonths") {
        newDays = getDaysBetweenDates(today, inThreeMonths);
        setDays(newDays);
        fetchReservations(newDays[0], newDays[newDays.length - 1]);
      } else if (value === "comingSixMonths") {
        newDays = getDaysBetweenDates(today, inThreeMonths);
        setDays(newDays);
        fetchReservations(newDays[0], newDays[newDays.length - 1]);
      } else if (value === "nextYear") {
        let firstNextYear = new Date(new Date().getFullYear() + 1, 0, 1);
        let allDaysLastMonthOfNextYear = getDaysInOneMonth(
          firstNextYear.getMonth() + 11,
          firstNextYear.getFullYear()
        );

        newDays = getDaysBetweenDates(
          firstNextYear,
          allDaysLastMonthOfNextYear[allDaysLastMonthOfNextYear.length - 1]
        );
        setDays(newDays);
        fetchReservations(newDays[0], newDays[newDays.length - 1]);
      } else if (value === "customMonth") {
        fetchReservationsForMonth(selectedMonth);
      }

      setIsCustomMonthSelected(value === "customMonth");
    },
    [
      fetchAllFutureReservations,
      fetchReservations,
      fetchReservationsForMonth,
      selectedMonth,
    ]
  );

  useEffect(() => {
    if (selectedObject !== undefined) {
      fetchReservationsForDateRange(selectedTimePeriod ?? "all");
    }
  }, [selectedObject, fetchReservationsForDateRange, selectedTimePeriod]);

  function getTotalRevenue() {
    return state
      .map(
        (x) =>
          x.salesAmountIncVAT! -
          x.cleaningCost! -
          x.channelCost! -
          x.ownerCommissionAmount!
      )
      ?.reduce((accumulator, currentValue) => accumulator + currentValue);
  }

  function getDateString() {
    if (days.length < 1) {
      return "";
    }
    return `(${dateTimeFormatter.format(days[0])} - ${dateTimeFormatter.format(
      days[days.length - 1]
    )})`;
  }

  return (
    <>
      <Snackbar
        className="custom-snack"
        style={{
          ...({ "--bgColor": snackbarState.color } as React.CSSProperties),
        }}
        anchorOrigin={{ vertical, horizontal }}
        open={snackbarState.show}
        autoHideDuration={4000}
        onClose={() => setSnackbarState((x) => ({ ...x, show: false }))}
        message={snackbarState.text}
        key={vertical + horizontal}
      />
      <BookingDetailsModal
        onReservationCancelled={(success: boolean) => {
          if (success) {
            setDrawerState((prev) => ({ ...prev, show: false }));
            setSnackbarState({
              show: true,
              color: "#44923d",
              text: translation?.bookings?.reservationWasCancelled!,
            });
          } else {
            setSnackbarState({
              show: true,
              color: "#d23a3a",
              text: translation?.bookings?.failedToCancelReservation!,
            });
          }
        }}
        onClose={() => setDrawerState((prev) => ({ ...prev, show: false }))}
        showDrawer={drawerState.show}
        imageUrl={imageUrl}
        booking={drawerState.booking}
      />
      <div className="bv-main">
        <div className="bv-inner">
          <div className="bv-title-main">
            <div className="bv-icon">
              <svg id="task-list-multiple" viewBox="0 0 24 24">
                <path
                  fill="currentcolor"
                  d="M10.75,24.011c-0.965,0-1.75-0.785-1.75-1.75v-1.25H6.75c-1.241,0-2.25-1.009-2.25-2.25v-0.75H2.25 c-1.241,0-2.25-1.009-2.25-2.25v-13.5c0-1.241,1.009-2.25,2.25-2.25h10.5c1.241,0,2.25,1.009,2.25,2.25v0.75h2.25 c1.241,0,2.25,1.009,2.25,2.25v0.75h2.75c0.965,0,1.75,0.785,1.75,1.75v14.5c0,0.965-0.785,1.75-1.75,1.75H10.75z M10.75,7.511 c-0.138,0-0.25,0.112-0.25,0.25v14.5c0,0.138,0.112,0.25,0.25,0.25h11.5c0.138,0,0.25-0.112,0.25-0.25v-14.5 c0-0.138-0.112-0.25-0.25-0.25H10.75z M6.75,4.511C6.336,4.511,6,4.847,6,5.261v13.5c0,0.414,0.336,0.75,0.75,0.75H9V7.761 c0-0.965,0.785-1.75,1.75-1.75H18v-0.75c0-0.414-0.336-0.75-0.75-0.75H6.75z M2.25,1.511c-0.414,0-0.75,0.336-0.75,0.75v13.5 c0,0.414,0.336,0.75,0.75,0.75H4.5V5.261c0-1.241,1.009-2.25,2.25-2.25h6.75v-0.75c0-0.414-0.336-0.75-0.75-0.75H2.25z"
                ></path>
                <path
                  fill="currentcolor"
                  d="M17.25,12.011c-0.414,0-0.75-0.336-0.75-0.75s0.336-0.75,0.75-0.75h3c0.414,0,0.75,0.336,0.75,0.75s-0.336,0.75-0.75,0.75 H17.25z"
                ></path>
                <path
                  fill="currentcolor"
                  d="M13.5,12.011c-0.2,0-0.389-0.078-0.53-0.22l-0.75-0.75C12.078,10.9,12,10.711,12,10.511s0.078-0.389,0.22-0.53 c0.141-0.142,0.33-0.22,0.53-0.22s0.389,0.078,0.53,0.22l0.22,0.22l0.97-0.97c0.142-0.142,0.33-0.22,0.53-0.22 s0.389,0.078,0.53,0.22c0.142,0.141,0.22,0.33,0.22,0.53s-0.078,0.389-0.22,0.53l-1.5,1.5C13.889,11.933,13.7,12.011,13.5,12.011z"
                ></path>
                <path
                  fill="currentcolor"
                  d="M17.25,16.511c-0.414,0-0.75-0.336-0.75-0.75s0.336-0.75,0.75-0.75h3c0.414,0,0.75,0.336,0.75,0.75s-0.336,0.75-0.75,0.75 H17.25z"
                ></path>
                <path
                  fill="currentcolor"
                  d="M13.5,16.511c-0.2,0-0.389-0.078-0.53-0.22l-0.75-0.75c-0.292-0.292-0.292-0.768,0-1.061c0.142-0.142,0.33-0.22,0.53-0.22 s0.389,0.078,0.53,0.22l0.22,0.22l0.97-0.97c0.142-0.142,0.33-0.22,0.53-0.22s0.389,0.078,0.53,0.22 c0.292,0.292,0.292,0.768,0,1.061l-1.5,1.5C13.889,16.433,13.7,16.511,13.5,16.511z"
                ></path>
                <path
                  fill="currentcolor"
                  d="M17.25,21.011c-0.414,0-0.75-0.336-0.75-0.75s0.336-0.75,0.75-0.75h3c0.414,0,0.75,0.336,0.75,0.75s-0.336,0.75-0.75,0.75 H17.25z"
                ></path>
                <path
                  fill="currentcolor"
                  d="M13.5,21.011c-0.2,0-0.389-0.078-0.53-0.22l-0.75-0.75c-0.292-0.292-0.292-0.768,0-1.061c0.142-0.142,0.33-0.22,0.53-0.22 s0.389,0.078,0.53,0.22l0.22,0.22l0.97-0.97c0.142-0.142,0.33-0.22,0.53-0.22s0.389,0.078,0.53,0.22 c0.292,0.292,0.292,0.768,0,1.061l-1.5,1.5C13.889,20.933,13.7,21.011,13.5,21.011z"
                ></path>
              </svg>
            </div>
            <h1>{translation.bookings?.bookings}</h1>
            <div className="bv-actions" style={{ placeItems: "flex-start" }}>
              <div>
                {ownersObjects.length > 1 && (
                  <select
                    className="custom-select custom-select-lg"
                    value={selectedObject}
                    onChange={(newValue: any) =>
                      onSelectedObjectChanged(newValue.target.value)
                    }
                  >
                    {ownersObjects.map((o) => {
                      return (
                        <option value={o.id} key={o.id}>
                          {o.name}
                        </option>
                      );
                    })}
                  </select>
                )}
              </div>
              <div>
                <select
                  className="custom-select custom-select-lg"
                  onChange={(newValue: any) =>
                    setSelectedTimePeriod(newValue.target.value)
                  }
                >
                  <option key="1" value="all">
                    {translation.common?.all}
                  </option>
                  <option key="2" value="thisMonth">
                    {translation.common?.thisMonth}
                  </option>
                  <option key="3" value="nextMonth">
                    {translation.common?.nextMonth}
                  </option>
                  <option key="4" value="comingThreeMonths">
                    {translation.common?.nextThreeMonths}
                  </option>
                  <option key="5" value="comingSixMonths">
                    {translation.common?.nextSixMonths}
                  </option>
                  <option key="6" value="nextYear">
                    {translation.common?.nextYear}
                  </option>
                  <option key="7" value="customMonth">
                    {translation.common?.customMonth}
                  </option>
                </select>
                {isCustomMonthSelected && (
                  <div className="vg-form-control-icon">
                    <input
                      autoFocus
                      className="month-picker"
                      onChange={(x: any) =>
                        fetchReservationsForMonth(x.target.value)
                      }
                      style={{
                        textTransform: "capitalize",
                        background: "transparent",
                        height: "40px",
                        borderRadius: "3px",
                        border: "1px solid #cfcecc",
                        paddingLeft: "1rem",
                        paddingRight: "1rem",
                        width: "100%",
                        marginTop: "10px",
                      }}
                      type="month"
                      id="start"
                      name="start"
                      min="2020-01"
                      value={selectedMonth}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          {isLoading && (
            <div style={{ textAlign: "center" }}>
              <Loader />
            </div>
          )}

          {selectedObject !== undefined && (
            <div>
              <div className="vg-panel">
                <div className="vg-panel-header">
                  <div className="vg-panel-title">
                    <h3>
                      {translation.bookings?.showingXBookings?.replace(
                        "{0}",
                        state.length.toString()
                      )}{" "}
                      {getDateString()}
                    </h3>
                  </div>
                  <div className="small text-muted">
                    {translation.bookings?.basedOnArrivalDate}
                  </div>
                </div>
                <table className="table table-list table-striped table-hover">
                  <thead className="thead-dark">
                    <tr>
                      <th style={{ width: "40px" }}>#</th>
                      <th style={{ width: "220px" }}>
                        <span style={{ display: "inline-block" }}>
                          {translation.bookings?.arrival} /&ensp;
                        </span>
                        <span style={{ display: "inline-block" }}>
                          {translation.bookings?.departure?.toLowerCase()}
                        </span>
                        <div
                          className="bv-hide-lg"
                          style={{ fontWeight: "normal" }}
                        >
                          {translation.bookings?.nights}
                        </div>
                      </th>
                      <th className="text-center bv-hide-sm">
                        {translation.bookings?.nights}
                      </th>
                      <th className="text-center bv-hide-sm">
                        {!state?.some(
                          (reservation) => reservation?.isCustomerDataHidden
                        )
                          ? translation.common?.customerName
                          : ""}
                      </th>
                      <th className="text-center bv-th-channel">
                        <span className="bv-hide-sm">
                          {translation.bookings?.channel}
                        </span>
                      </th>
                      <th className="text-right text-nowrap">
                        {translation.bookings?.salesAmount}
                        <div
                          className="bv-hide-lg"
                          style={{ fontWeight: "normal" }}
                        >
                          {translation.bookings?.revenue}
                        </div>
                      </th>
                      {!hidePayDetails && (
                        <th className="text-right bv-hide-sm">
                          {translation.bookings?.yourRevenue}
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {state.map((booking, index) => (
                      <tr
                        key={`${booking.bookingCode}_${index}`}
                        onClick={() => setDrawerState({ show: true, booking })}
                        className={`align-middle clickable ${
                          booking.cancelled ? "table-danger" : ""
                        }`}
                        data-toggle="modal"
                        data-target="#moda_booking_bookingcom"
                      >
                        <td className="text-muted">{index + 1}</td>
                        <td>
                          <span style={{ display: "inline-block" }}>
                            {dateTimeFormatter.format(booking.arrival)} -&ensp;
                          </span>
                          <span style={{ display: "inline-block" }}>
                            {dateTimeFormatter.format(booking.departure)}
                          </span>
                          <div className="small text-muted bv-hide-lg">
                            {
                              getDaysBetweenDates(
                                booking.arrival!,
                                booking.departure!
                              ).length
                            }{" "}
                            nights
                          </div>
                        </td>
                        <td className="text-center bv-hide-sm">
                          {
                            getDaysBetweenDates(
                              booking.arrival!,
                              booking.departure!
                            ).length
                          }{" "}
                          nights
                        </td>
                        <td className="text-center bv-hide-sm">
                          {booking.isCustomerDataHidden
                            ? ""
                            : booking.customerName}
                        </td>
                        <td className="bv-td-channel">
                          <div
                            className="bv-channel"
                            style={{
                              backgroundImage: `url(${
                                booking.channelLogoImageUrl ||
                                `${imageUrl}${booking.channelLogoImageId}.png`
                              })`,
                              margin: "0 auto",
                            }}
                          ></div>
                        </td>

                            <td className="text-right">
                              <b>
                                {currencyFormatter(
                                  booking.currency!,
                                  booking.salesAmountIncVAT!
                                , languageCulture)}
                              </b>
                              {!hidePayDetails && <div className="bv-hide-lg">
                                {currencyFormatter(
                                  booking.currency!,
                                  booking.salesAmountIncVAT! -
                                    booking.cleaningCost! -
                                    booking.channelCost! -
                                    booking.ownerCommissionAmount!
                                , languageCulture)}
                              </div>}
                            </td>
                            {!hidePayDetails && <td className="text-right bv-hide-sm">
                              {currencyFormatter(
                                booking.currency!,
                                booking.salesAmountIncVAT! -
                                  booking.cleaningCost! -
                                  booking.channelCost! -
                                  booking.ownerCommissionAmount!
                              , languageCulture)}
                            </td>}
                      </tr>
                    ))}
                    <tr className="align-middle" data-toggle="modal">
                      <td className="vg-bg-yellow-100" colSpan={2}>
                        <b className="bv-hide-sm">
                          {translation.bookings?.total}:
                        </b>
                        <div className="bv-hide-lg">
                          <b>
                            {translation.bookings?.salesAmount} (
                            {translation.bookings?.total?.toLowerCase()}):
                          </b>
                          {!hidePayDetails && (
                            <div>
                              {translation.bookings?.yourRevenue} (
                              {translation.bookings?.total?.toLowerCase()}):
                            </div>
                          )}
                        </div>
                      </td>
                      <td className="vg-bg-yellow-100 bv-hide-sm"></td>
                      <td className="vg-bg-yellow-100 bv-hide-sm"></td>
                      <td className="vg-bg-yellow-100 text-right" colSpan={2}>
                        <b>
                          {state.length > 0 &&
                            currencyFormatter(
                              state[0].currency!,
                              state
                                .map((x) => x.salesAmountIncVAT!)
                                ?.reduce(
                                  (accumulator, currentValue) =>
                                    accumulator! + currentValue!
                                ), languageCulture)}
                        </b>
                        {!hidePayDetails && (
                          <div className="bv-hide-lg">
                            {state.length > 0 &&
                              currencyFormatter(
                                state[0].currency!,
                                getTotalRevenue()
                              , languageCulture)}
                          </div>
                        )}
                      </td>
                      {!hidePayDetails && (
                        <td className="vg-bg-yellow-100 text-right bv-hide-sm">
                          {state.length > 0 &&
                            currencyFormatter(
                              state[0].currency!,
                              getTotalRevenue()
                            , languageCulture)}
                        </td>
                      )}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};