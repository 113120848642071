import { createContext } from "react"
import {GetOwnerPortalChannelSettingsRs, UserModel} from "./generated_api"

export interface UC {
  user: UserModel | undefined,
  logout: Function,
  updateContext?: (newUserObject: UserModel) => void,
  hasFullAccess?: boolean,
  isBVUser?: boolean,
  ownerPortalChannelSettings?: GetOwnerPortalChannelSettingsRs,
  hidePayDetails: boolean
}

export const UserContext = createContext<UC>({
  user: undefined,
  logout: () => {},
  hidePayDetails: false
})