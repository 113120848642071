import React, { FC, useContext } from "react"

// Components
import { TranslationContext } from "../translationcontext"

// Types
import { AccountInfoAddressFragmentProps } from "./interfaces"

export const AccountInfoAddressFragment: FC<AccountInfoAddressFragmentProps> = (
  {
    loading, address, updateAccountInfo, allCountries
  }) => {
  const translations = useContext(TranslationContext)

  const handleChange = (event: any) => {
    updateAccountInfo("address", {
      ...address,
      [event.currentTarget.name]: event.currentTarget.value
    })
  }

  return (
    <>
      <div className="vg-form-group">
        <label>{translations.contact?.address}</label>
        <div>
          <input
            className="form-control form-control-lg"
            disabled={loading}
            type="text"
            name="streetAddress1"
            onBlur={handleChange}
            defaultValue={address.streetAddress1}
          />
        </div>
      </div>
      <div className="vg-form-group">
        <label>{translations.common?.postalCode}</label>
        <div>
          <input
            className="form-control form-control-lg"
            disabled={loading}
            type="text"
            name="zipCode"
            onBlur={handleChange}
            defaultValue={address.zipCode}
          />
        </div>
      </div>
      <div className="vg-form-group">
        <label>{translations.common?.city}</label>
        <div>
          <input
            className="form-control form-control-lg"
            disabled={loading}
            type="text"
            name="city"
            onBlur={handleChange}
            defaultValue={address.city}
          />
        </div>
      </div>
      <div className="vg-form-group">
        <label>{translations.common?.country}</label>
        <div>
          <select
            className="custom-select custom-select-lg"
            disabled={loading}
            name="countryCode"
            onChange={handleChange}
            value={address.countryCode}
          >
            {allCountries.map(l => {
              return <option key={l.code} value={l.code} label={l.name}>{l.name}</option>
            })}
          </select>
        </div>
      </div>
    </>
  )
}
