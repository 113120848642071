import {useMutation, useQueryClient} from "@tanstack/react-query";
import {CancelReservation} from "../api";

export const useCancelReservation = () => {
    const queryClient = useQueryClient()

    return useMutation(["cancelReservation"],
        async (
            {
                bookingCode, roomUnitId, roomTypeId
            }: {
                bookingCode: string, roomUnitId: string, roomTypeId: string
            }): Promise<boolean> => {
            return CancelReservation(
                bookingCode,
                roomTypeId,
                roomUnitId
            )
        },
        {
            onSettled: (data, _, {roomUnitId, roomTypeId}) => {
                if (data) {
                    setTimeout(() => {
                        queryClient.invalidateQueries({queryKey: ["reservationsAndRestrictions", roomUnitId]});
                        queryClient.invalidateQueries({queryKey: ["reservationsAndRestrictions", roomTypeId]});
                    }, 400)
                }
            }
        })
}