import {useMemo} from 'react';
import {getDaysInMonthUTC, getMonthDate} from "../helpers";

export const useDays = (selectedMonth: string) => {
    return useMemo(() => {
        const month = getMonthDate(selectedMonth);
        const [daysInMonth, daysLastMonth, daysNextMonth] = getDaysInMonthUTC(month.getMonth(), month.getFullYear());

        return {
            lastMonth: daysLastMonth,
            thisMonth: daysInMonth,
            nextMonth: daysNextMonth,
        };
    }, [selectedMonth]);
};