import {useMutation, useQueryClient} from "@tanstack/react-query";
import {NewReservation} from "../api";

export const useCreateNewBooking = () => {
    const queryClient = useQueryClient()

    return useMutation(["createNewBooking"],
        async (
            {
                selectedFromDate,
                endDate,
                guestFirstname,
                guestLastname,
                guestIsOwner,
                guestRoomId,
                numberOfGuests
            }: {
                selectedFromDate: Date,
                endDate: Date,
                guestFirstname: string,
                guestLastname: string,
                guestIsOwner: boolean,
                guestRoomId: string,
                numberOfGuests?: number
            }): Promise<boolean> => {
            return NewReservation(
                selectedFromDate,
                endDate,
                guestFirstname,
                guestLastname,
                guestIsOwner,
                guestRoomId,
                numberOfGuests!,
                guestRoomId
            )
        },
        {
            onSettled: (data, _, {guestRoomId}) => {
                if (data) {
                    setTimeout(() => {
                        queryClient.invalidateQueries({queryKey: ["reservationsAndRestrictions", guestRoomId]});
                    }, 400)
                }
            }
        })
}