import React, { FC, useContext, useEffect, useState } from "react"

// Components
import { Loader } from "@mantine/core"
import { NavLink, useParams } from "react-router-dom"
import SelectAccountModal from "./../components/select_account_modal"
import { Key, Lock, Person, QuestionMarkSolid } from "../components/icons"
import { TranslationContext } from "../translationcontext"

// API
import {
  GetOwnerOrganizations,
  SendResetPasswordEmail,
  SetPassword as APISetPassword,
  ValidateResetPasswordGuid
} from "../api"

// Lib
import { isUser } from "../helpers"

// Types
import { SetPasswordProps } from "./interfaces"
import { OwnerOrganization, UserModel } from "../generated_api"


export const SetPassword: FC<SetPasswordProps> = ({ FirstTime, IsOrgLink }) => {
  const translations = useContext(TranslationContext)
  const [loading, setLoading] = useState(false)
  const [validatedUser, setValidatedUser] = useState<UserModel | undefined | null>(undefined)
  const [accountModalState, setAccountModalState] = React.useState<{
    show: boolean,
    email: string,
    accounts: Array<any>
  }>({ show: false, email: "", accounts: [] })
  const [error, setError] = useState<string | undefined>()
  const [linkSent, setLinkSent] = useState(false)
  const [passwordSet, setPasswordSet] = useState(false)

  let { token, orgId } = useParams<{ token: string | undefined, orgId: string | undefined }>()

  useEffect(() => {
    setError(undefined)
    if (token !== undefined) {
      setValidatedUser(undefined)
      ValidateResetPasswordGuid(token).then(result => {
        if (isUser(result)) {
          setValidatedUser(result)
        } else {
          setValidatedUser(null)
        }
      })
    }
  }, [token, FirstTime])

  const SetPassword = async (event: any) => {
    event.preventDefault()
    setError(undefined)
    setLoading(true)
    let pass = event.target.password.value

    if (pass !== event.target.passwordrepeat.value) {
      setError(translations?.login?.passwordsMustMatch)
      setLoading(false)
      return
    }

    if (!token) {
      setLoading(false)
      return
    }

    let result = await APISetPassword(token, pass)
    if (result === true) {
      setPasswordSet(true)
    } else {
      setError(translations?.login?.somethingWentWrong)
    }
    setLoading(false)
  }

  const SendResetPasswordLink = async (event: any) => {
    event.preventDefault()
    setLoading(true)
    setError(undefined)
    let email = event.target.email.value

    let orgs = await GetOwnerOrganizations(email)

    if (orgs.length === 1) {
      if (await SendResetPasswordEmail(email, orgs[0].organizationId) === true) {
        setLinkSent(true)
      } else {
        setError(translations?.login?.somethingWentWrong)
      }
    } else if (orgs.length > 1) {
      setAccountModalState({ accounts: orgs, show: true, email: email })
    }
    setLoading(false)
  }

  const AccountSelected = async (email: string, organization: OwnerOrganization) => {
    setAccountModalState(x => ({ ...x, show: false }))
    setLoading(true)

    let result = await SendResetPasswordEmail(email, organization.organizationId!)
    if (result === true) {
      setLinkSent(true)
    } else {
      setError(translations.login?.somethingWentWrong)
    }
    setLoading(false)
  }

  const ResetPasswordForm = () => {
    return (
      <form className="bv-login-form" onSubmit={SendResetPasswordLink} style={{ padding: "20px" }}>
        <div className="vg-form-control-icon">
          <input disabled={loading} required name="email" className="form-control form-control-lg" type="text"
                 placeholder="Email" />
          <Person />
        </div>
        <button type="submit" className="btn btn-lg">
          {loading && <Loader size="sm" role="status" aria-hidden="true"
                              style={{ marginRight: "5px", verticalAlign: "middle" }} />}
          {(loading && translations.login?.sending) || translations.login?.sendLink}
        </button>
        {error && <p style={{ color: "orangered", position: "absolute" }}>{error}</p>}
      </form>
    )
  }

  const CreateNewPasswordForm = () => {
    return (
      <form className="bv-login-form" onSubmit={SetPassword} style={{ padding: "20px" }}>
        <div className="vg-form-control-icon">
          <input disabled={loading} required name="password" className="form-control form-control-lg" type="password"
                 placeholder={translations.login?.password} />
          <Lock />
        </div>
        <div className="vg-form-control-icon">
          <input disabled={loading} required name="passwordrepeat" className="form-control form-control-lg"
                 type="password" placeholder={translations.login?.repeatPassword} />
          <Lock />
        </div>
        <button type="submit" className="btn btn-lg">
          {loading && <Loader size="sm" role="status" aria-hidden="true"
                              style={{ marginRight: "5px", verticalAlign: "middle" }} />}
          {(loading && `${translations.login?.pleaseWait}..`) || translations.login?.continue}
        </button>
        {error && <p style={{ color: "orangered", position: "absolute" }}>{error}</p>}
      </form>
    )
  }

  if (token !== undefined) {
    if (validatedUser === undefined) {
      return (
        <div className="bv-login">
          <div style={{ display: "inline-grid", justifyItems: "center" }}>
            <Loader size="sm" role="status" aria-hidden="true" style={{
              verticalAlign: "middle",
              color: "white",
              width: "50px",
              height: "50px",
              marginBottom: "10px"
            }} />
          </div>
        </div>
      )
    } else if (validatedUser === null) {
      return (
        <div className="bv-login">
          <div style={{ display: "inline-grid", justifyItems: "center" }}>
            <div className="bv-icon" style={{
              height: "68px",
              width: "68px",
              padding: "18px",
              background: "#517980",
              borderRadius: "50%",
              color: "white"
            }}>
              {!token ? <QuestionMarkSolid /> : <Key />}
            </div>
            <h1>
              {translations.login?.invalidLink}
            </h1>
            <div style={{ margin: 0 }} className="vg-text-dots-inlineblock small">
              <NavLink to="/login"><b>{translations.login?.backToLogin}</b></NavLink>
              <NavLink onClick={() => {
              }} to="#"><b>{translations.login?.help}</b></NavLink>
            </div>
            <div className="bv-powered">
              <a href="https://www.bookvisit.com" className="small" target="_blank" rel="noopener noreferrer">
                <span>Powered by BookVisit,</span>
                <span>part of Visit Group AB</span>
              </a>
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <>
      <div className="bv-login">

        <div style={{ display: "inline-grid", justifyItems: "center" }}>
          <SelectAccountModal onCancel={() => setAccountModalState(p => ({ ...p, show: false }))}
                              onClose={() => setAccountModalState(p => ({ ...p, show: false }))}
                              open={accountModalState.show} accounts={accountModalState.accounts}
                              email={accountModalState.email} accountSelected={AccountSelected} />
          <div className="bv-icon" style={{
            height: "68px",
            width: "68px",
            padding: "18px",
            background: "#517980",
            borderRadius: "50%",
            color: "white"
          }}>
            {token === undefined ? <QuestionMarkSolid /> : <Key />}
          </div>
          {!linkSent && !passwordSet && <> <h1>
            {FirstTime ? translations.login?.createPassword : translations.login?.forgotPassword}
          </h1>
            <p style={{ color: "#d4dddf", maxWidth: "400px" }}>{
              token === undefined ?
                translations.login?.resetPasswordLinkInfo
                : translations.login?.passwordStrengthRecommendation
            }</p></>}
          {linkSent ? <h1>{translations.login?.linkWasSent}!</h1> : passwordSet ?
            <h1>{translations.login?.newPasswordWasSet}</h1> : token !== undefined ? <CreateNewPasswordForm /> :
              <ResetPasswordForm />}
          <div style={{ margin: 0 }} className="vg-text-dots-inlineblock small">
            <NavLink
              to={validatedUser !== undefined && validatedUser !== null ? `/login/${validatedUser.organizationId}` : IsOrgLink ? `/login/${orgId}` : "/login"}><b>{translations.login?.backToLogin}</b></NavLink>
            <NavLink onClick={() => {
            }} to="#"><b>{translations.login?.help}</b></NavLink>
          </div>
          <div className="bv-powered">
            <a href="https://www.bookvisit.com" className="small" target="_blank" rel="noopener noreferrer">
              <span>Powered by BookVisit,</span>
              <span>part of Visit Group AB</span>
            </a>
          </div>
        </div>
      </div>
    </>
  )
}