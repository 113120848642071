import {useIsFetching, useIsMutating} from '@tanstack/react-query';

export const useIsCalendarLoading = () => {
    const isFetchingReservationsAndRestrictions = useIsFetching({queryKey: ["reservationsAndRestrictions"]}) > 0;
    const isMutatingCreateNewBlock = useIsMutating({mutationKey: ["createNewBlock"]}) > 0;
    const isMutatingCreateNewBooking = useIsMutating({mutationKey: ["createNewBooking"]}) > 0;
    const isMutatingRemoveReservationBlock = useIsMutating({mutationKey: ["removeReservationBlock"]}) > 0;

    return isFetchingReservationsAndRestrictions || isMutatingCreateNewBlock || isMutatingCreateNewBooking || isMutatingRemoveReservationBlock;
};
