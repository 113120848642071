import React, { FC, useContext } from "react"

// Components
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import { TranslationContext } from "../translationcontext"

// Types
import { SelectAccountModalProps } from "./interfaces"
import { OwnerOrganization } from "../generated_api"


const SelectAccountModal: FC<SelectAccountModalProps> = (
  {
    accountSelected, accounts, onClose, onCancel, email, open
  }) => {
  const translations = useContext(TranslationContext)

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{translations.login?.selectOrganization}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {accounts.map((a: OwnerOrganization) => <Button key={a.organizationId} fullWidth
                                                          onClick={() => accountSelected(email, a)}>{a.organizationName}</Button>)}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onCancel()} color="primary">
          {translations.common?.cancel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SelectAccountModal