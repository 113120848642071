import React, {FC, useContext, useEffect, useState} from "react"

// Components
import {UserContext} from "../usercontext"
import {TranslationContext} from "../translationcontext"
import {AccountInfoForm} from "../components/account_info_form"
import {PaymentInfoForm} from "../components/payment_info_form"
import {Loader} from "@mantine/core"

// API
import {GetProfile, SaveAccountInfo, SavePaymentInfo} from "../api"

// Lib
import {isUser, upperCaseFirstCharacter} from "../helpers"
import {AccountInfo, PaymentInfo, UserProfile} from "../generated_api"

const isUserProfile = (profile: UserProfile): profile is UserProfile => {
    return (profile as UserProfile)?.accountInfo !== undefined  // TODO(AXD): Pick something more static from the final schema.
}

export const Profile: FC = () => {
    const {user, hasFullAccess, isBVUser, updateContext} = useContext(UserContext)
    const translations = useContext(TranslationContext)

    const [state, setState] = useState<{
        loading: boolean,
        profile: UserProfile | undefined,
        error: string | undefined
    }>
    ({
        loading: true,
        profile: undefined,
        error: undefined
    })

    const updateAccountInfo = (accountInfo: AccountInfo) => {
        const profile = {...state.profile, accountInfo: accountInfo} as UserProfile
        setState({...state, profile})
    }

    const saveAccountInfo = async (event: any) => {
        event.preventDefault()

        setState(s => ({...s, loading: true}))

        let result = await SaveAccountInfo(state.profile?.accountInfo!)

        if (isUser(result)) {
            updateContext!(result)
        }

        if (result instanceof Error) {
            setState(s => ({...s, error: result.message}))
        } else {
            setState(s => ({...s, error: undefined}))
        }
        setState(s => ({...s, loading: false}))
    }

    const updatePaymentInfo = (paymentInfo: PaymentInfo) => {
        const profile = {...state.profile, paymentInfo: paymentInfo} as UserProfile
        setState({...state, profile})
    }

    const savePaymentInfo = async (event: any) => {
        event.preventDefault()

        setState(s => ({...s, loading: true}))

        let result = await SavePaymentInfo(state.profile?.paymentInfo!)
        if (result instanceof Error) {
            setState(s => ({...s, error: result.message}))
        } else {
            setState(s => ({...s, error: undefined}))
        }
        setState(s => ({...s, loading: false}))
    }

    useEffect(() => {
        const init = async () => {
            setState(s => ({...s, loading: true}))
            let result: UserProfile = await GetProfile()
            if (isUserProfile(result)) {
                setState({profile: result, loading: false, error: undefined})
            } else {
                setState({
                    profile: undefined,
                    loading: false,
                    error: translations.common?.errorWhileLoadingProfile
                })
            }
        }
        init()
    }, [user, translations.common?.errorWhileLoadingProfile])

  return (
    <div className="bv-main">
      <div className="bv-inner">
        <div className="bv-title-main">
          <h1>{translations.common?.profileForX?.replace("{0}", user!.firstName ? upperCaseFirstCharacter(user!.firstName!) : user?.email!)}</h1>
          {!hasFullAccess && isBVUser &&
            <h3 style={{ color: "dimgray" }}>{translations.common?.changesDisabledInAssumeMode}</h3>}
        </div>
        {state.profile ? (
          <>
            <AccountInfoForm
              loading={state.loading}
              accountInfo={state.profile.accountInfo!}
              updateAccountInfo={updateAccountInfo}
              saveAccountInfo={saveAccountInfo}
            />
            <PaymentInfoForm
              loading={state.loading}
              paymentInfo={state.profile.paymentInfo!}
              updatePaymentInfo={updatePaymentInfo}
              savePaymentInfo={savePaymentInfo}
            />
          </>
        ) : (
          state.error || <div style={{ textAlign: "center" }}><Loader /></div>
        )}
      </div>
    </div>
  )
}
