import React, { FC, useContext, useState } from "react"

// Components
import { Loader } from "@mantine/core"
import { UserContext } from "../usercontext"
import { Navigate, NavLink, useParams } from "react-router-dom"
import { House as HouseIcon, Lock as LockIcon, Office as OfficeIcon, Person as PersonIcon } from "./../components/icons"
import { TranslationContext } from "../translationcontext"

// API
import { Login as APILogin } from "../api"

// Lib
import { isUser } from "../helpers"

// Types
import { LoginProps } from "./interfaces"
import { UserModel } from "../generated_api"


export const Login: FC<LoginProps> = ({ LoginSuccessful }) => {
  const userContext = useContext(UserContext)
  const translations = useContext(TranslationContext)
  let { orgId } = useParams<{ orgId: string | undefined }>()

  const [state, setState] = useState<{ loading: boolean, error: string | undefined }>({
    loading: false,
    error: undefined
  })

  const login = async (event: any) => {
    event.preventDefault()

    setState({ loading: true, error: undefined })
    let user = event.target.username.value
    let pass = event.target.password.value

    if (orgId === undefined) {
      orgId = event.target.organizationid.value
    }
    if (!orgId) {
      setState({ loading: false, error: undefined })
      return
    }

    let result: UserModel = await APILogin(user, pass, orgId)

    if (result instanceof Error) {
      setState({ loading: false, error: result.message })
    } else if (isUser(result)) {
      result.timeOfAuth = new Date()
      localStorage.setItem("bv_op_userinfo", JSON.stringify(result))
      LoginSuccessful(result)
    }
  }

  if (userContext.user?.id !== undefined) {
    return <Navigate to={"/"} />
  }

  return (
    <>
      <div className="bv-login">
        <div style={{ display: "inline-grid", justifyItems: "center" }}>
          <div className="bv-icon" style={{
            height: "68px",
            width: "68px",
            padding: "18px",
            background: "#517980",
            borderRadius: "50%",
            color: "white"
          }}>
            <HouseIcon />
          </div>
          <h1>
            {translations.login?.login}
          </h1>
          <form onSubmit={login} className="bv-login-form">
            <div className="vg-form-control-icon">
              <input autoComplete="username" required disabled={state.loading} name="username"
                     className="form-control form-control-lg" type="text" placeholder={translations.login?.username} />
              <PersonIcon />
            </div>
            <div className="vg-form-control-icon">
              <input autoComplete="current-password" required disabled={state.loading} name="password"
                     className="form-control form-control-lg" type="password"
                     placeholder={translations.login?.password} />
              <LockIcon />
            </div>
            {orgId === undefined && <div className="vg-form-control-icon">
              <input required disabled={state.loading} name="organizationid" className="form-control form-control-lg"
                     type="text" placeholder="Organization Id" />
              <OfficeIcon />
            </div>}
            <button disabled={state.loading} type="submit" className="btn btn-lg">
              {state.loading && <Loader size="sm" role="status" aria-hidden="true"
                                        style={{ marginRight: "5px", verticalAlign: "middle" }} />}
              {(state.loading && translations.login?.signingIn) || translations.login?.continue}
            </button>
            {state.error && <p style={{ color: "orangered", position: "absolute" }}>{state.error}</p>}
          </form>
          <div className="vg-text-dots-inlineblock small">
            <NavLink
              to={orgId === undefined ? "/forgotpassword" : `/forgotpassword/${orgId}`}><b>{translations.login?.forgotPassword}?</b></NavLink>
          </div>
          <div className="bv-powered">
            <a title="Powered by BookVisit, part of Visit Group AB" href="https://www.bookvisit.com" className="small" target="_blank" rel="noreferrer noopener">
              <span>Powered by BookVisit,</span>
              <span>part of Visit Group AB</span>
            </a>
          </div>
        </div>
      </div>
    </>
  )
}