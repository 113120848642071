import React from "react"

export const House = () =>
  <svg id="house-chimney" viewBox="0 0 24 24">
    <path fill="currentcolor"
          d="M23.8,11.9L21,9.1V4.2c0-0.4-0.3-0.8-0.8-0.8h-3.8c-0.3,0-0.6,0.2-0.7,0.5l-2.2-2.2c-0.9-0.9-2.3-0.9-3.2,0L0.2,11.9 c-0.3,0.3-0.3,0.8,0,1.1c0.3,0.3,0.8,0.3,1.1,0L3,11.2v10.9c0,0.4,0.3,0.8,0.8,0.8h6c0.4,0,0.8-0.3,0.8-0.8v-6 c0-0.4,0.3-0.8,0.8-0.8h1.5c0.4,0,0.8,0.3,0.8,0.8v6c0,0.4,0.3,0.8,0.8,0.8h6c0.4,0,0.8-0.3,0.8-0.8V11.2l1.7,1.7 c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.4-0.1,0.5-0.2C24.1,12.7,24.1,12.2,23.8,11.9z M17.3,4.9h2.3v2.7l-2.3-2.2V4.9z M19.5,21.4H15 v-5.3c0-1.2-1-2.3-2.3-2.3h-1.5c-1.2,0-2.3,1-2.3,2.3v5.3H4.5V9.8l7-7c0.3-0.3,0.8-0.3,1.1,0l7,7V21.4z"></path>
  </svg>

export const Person = () =>
  <svg id="mt-person" viewBox="0 0 24 24">
    <path fill="currentcolor"
          d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z"></path>
    <path fill="none" d="M0 0h24v24H0z"></path>
  </svg>

export const Lock = () =>
  <svg id="mt-lock" viewBox="0 0 24 24">
    <path fill="currentcolor"
          d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"></path>
    <path fill="none" d="M0 0h24v24H0z"></path>
  </svg>

export const Pen = () =>
  <svg id="task-list-pen" viewBox="0 0 24 24">
    <path fill="currentcolor"
          d="M21.8,8.3c-1.2,0-2.3,1-2.3,2.3v9.8c0,0.1,0,0.2,0.1,0.3l1.5,3c0.1,0.3,0.4,0.4,0.7,0.4s0.5-0.2,0.7-0.4l1.5-3 c0.1-0.1,0.1-0.2,0.1-0.3v-9.8C24,9.3,23,8.3,21.8,8.3z M22.5,20.1l-0.8,1.5L21,20.1v-9.6c0-0.4,0.3-0.8,0.8-0.8s0.8,0.3,0.8,0.8 V20.1z"></path>
    <path fill="currentcolor"
          d="M19.5,3.8c0-2.1-1.7-3.8-3.8-3.8h-12C1.7,0,0,1.7,0,3.8v12c0,0.4,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8v-12c0-1.2,1-2.3,2.3-2.3 S6,2.5,6,3.8S5,6,3.8,6C3.3,6,3,6.3,3,6.8v15.8C3,23.3,3.7,24,4.5,24h12c0.8,0,1.5-0.7,1.5-1.5V6.7C18.9,6,19.5,5,19.5,3.8z M16.5,22.5h-12v-15h11.3c0.3,0,0.5,0,0.8-0.1V22.5z M15.8,6h-9c0.5-0.6,0.8-1.4,0.8-2.3S7.2,2.1,6.7,1.5h9c1.2,0,2.3,1,2.3,2.3 S17,6,15.8,6z"></path>
    <path fill="currentcolor"
          d="M12.2,10l-1.7,1.7l-1-1c-0.3-0.3-0.8-0.3-1.1,0s-0.3,0.8,0,1.1l1.5,1.5c0.1,0.1,0.3,0.2,0.5,0.2s0.4-0.1,0.5-0.2l2.3-2.3 c0.3-0.3,0.3-0.8,0-1.1S12.5,9.7,12.2,10z"></path>
    <path fill="currentcolor"
          d="M12.2,15.2l-1.7,1.7l-1-1c-0.3-0.3-0.8-0.3-1.1,0s-0.3,0.8,0,1.1l1.5,1.5c0.1,0.1,0.3,0.2,0.5,0.2s0.4-0.1,0.5-0.2l2.3-2.3 c0.3-0.3,0.3-0.8,0-1.1S12.5,14.9,12.2,15.2z"></path>
  </svg>

export const Office = () =>
  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
    <path d="M0 0h24v24H0z" fill="none" />
    <path fill="currentcolor"
          d="M12 7V3H2v18h20V7H12zM6 19H4v-2h2v2zm0-4H4v-2h2v2zm0-4H4V9h2v2zm0-4H4V5h2v2zm4 12H8v-2h2v2zm0-4H8v-2h2v2zm0-4H8V9h2v2zm0-4H8V5h2v2zm10 12h-8v-2h2v-2h-2v-2h2v-2h-2V9h8v10zm-2-8h-2v2h2v-2zm0 4h-2v2h2v-2z" />
  </svg>

export const QuestionMark = () =>
  <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="question-circle"
       className="svg-inline--fa fa-question-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
       viewBox="0 0 512 512">
    <path fill="currentColor"
          d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z"></path>
  </svg>

export const QuestionMarkSolid = () =>
  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="question"
       className="svg-inline--fa fa-question fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg"
       viewBox="0 0 384 512">
    <path fill="currentColor"
          d="M202.021 0C122.202 0 70.503 32.703 29.914 91.026c-7.363 10.58-5.093 25.086 5.178 32.874l43.138 32.709c10.373 7.865 25.132 6.026 33.253-4.148 25.049-31.381 43.63-49.449 82.757-49.449 30.764 0 68.816 19.799 68.816 49.631 0 22.552-18.617 34.134-48.993 51.164-35.423 19.86-82.299 44.576-82.299 106.405V320c0 13.255 10.745 24 24 24h72.471c13.255 0 24-10.745 24-24v-5.773c0-42.86 125.268-44.645 125.268-160.627C377.504 66.256 286.902 0 202.021 0zM192 373.459c-38.196 0-69.271 31.075-69.271 69.271 0 38.195 31.075 69.27 69.271 69.27s69.271-31.075 69.271-69.271-31.075-69.27-69.271-69.27z"></path>
  </svg>

export const Key = () =>
  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="key" className="svg-inline--fa fa-key fa-w-16"
       role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path fill="currentColor"
          d="M512 176.001C512 273.203 433.202 352 336 352c-11.22 0-22.19-1.062-32.827-3.069l-24.012 27.014A23.999 23.999 0 0 1 261.223 384H224v40c0 13.255-10.745 24-24 24h-40v40c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24v-78.059c0-6.365 2.529-12.47 7.029-16.971l161.802-161.802C163.108 213.814 160 195.271 160 176 160 78.798 238.797.001 335.999 0 433.488-.001 512 78.511 512 176.001zM336 128c0 26.51 21.49 48 48 48s48-21.49 48-48-21.49-48-48-48-48 21.49-48 48z"></path>
  </svg>

export const Calendar = () =>
  <svg id="calendar" viewBox="0 0 24 24">
    <path fill="currentcolor"
          d="M21.75,3H18V.75a.75.75,0,0,0-1.5,0V3h-9V.75A.75.75,0,0,0,6,.75V3H2.25A2.25,2.25,0,0,0,0,5.25v16.5A2.25,2.25,0,0,0,2.25,24h19.5A2.25,2.25,0,0,0,24,21.75V5.25A2.25,2.25,0,0,0,21.75,3Zm.75,18.75a.76.76,0,0,1-.75.75H2.25a.76.76,0,0,1-.75-.75V5.25a.76.76,0,0,1,.75-.75H6V6A.75.75,0,0,0,7.5,6V4.5h9V6A.75.75,0,0,0,18,6V4.5h3.75a.76.76,0,0,1,.75.75Z"></path>
    <path fill="currentcolor"
          d="M18.75,9h-9A.76.76,0,0,0,9,9.75V13.5H5.25a.76.76,0,0,0-.75.75v4.5a.76.76,0,0,0,.75.75h13.5a.76.76,0,0,0,.75-.75v-9A.76.76,0,0,0,18.75,9ZM18,13.5H15v-3h3Zm-4.5-3v3h-3v-3ZM6,15H9v3H6Zm4.5,0h3v3h-3ZM15,18V15h3v3Z"></path>
  </svg>

export const CalendarPlus = () =>
  <svg aria-hidden="true" style={{ verticalAlign: "middle" }} focusable="false" data-prefix="far"
       data-icon="calendar-plus" className="svg-inline--fa fa-calendar-plus fa-w-14" role="img"
       xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path fill="currentColor"
          d="M336 292v24c0 6.6-5.4 12-12 12h-76v76c0 6.6-5.4 12-12 12h-24c-6.6 0-12-5.4-12-12v-76h-76c-6.6 0-12-5.4-12-12v-24c0-6.6 5.4-12 12-12h76v-76c0-6.6 5.4-12 12-12h24c6.6 0 12 5.4 12 12v76h76c6.6 0 12 5.4 12 12zm112-180v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48zm-48 346V160H48v298c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z"></path>
  </svg>

export const Ban = () =>
  <svg aria-hidden="true" style={{ verticalAlign: "middle" }} focusable="false" data-prefix="fas" data-icon="ban"
       className="svg-inline--fa fa-ban fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path fill="currentColor"
          d="M256 8C119.034 8 8 119.033 8 256s111.034 248 248 248 248-111.034 248-248S392.967 8 256 8zm130.108 117.892c65.448 65.448 70 165.481 20.677 235.637L150.47 105.216c70.204-49.356 170.226-44.735 235.638 20.676zM125.892 386.108c-65.448-65.448-70-165.481-20.677-235.637L361.53 406.784c-70.203 49.356-170.226 44.736-235.638-20.676z"></path>
  </svg>

export const StartIcon = () =>
  <svg id="house-chimney" viewBox="0 0 24 24">
    <path fill="currentcolor"
          d="M23.8,11.9L21,9.1V4.2c0-0.4-0.3-0.8-0.8-0.8h-3.8c-0.3,0-0.6,0.2-0.7,0.5l-2.2-2.2c-0.9-0.9-2.3-0.9-3.2,0L0.2,11.9 c-0.3,0.3-0.3,0.8,0,1.1c0.3,0.3,0.8,0.3,1.1,0L3,11.2v10.9c0,0.4,0.3,0.8,0.8,0.8h6c0.4,0,0.8-0.3,0.8-0.8v-6 c0-0.4,0.3-0.8,0.8-0.8h1.5c0.4,0,0.8,0.3,0.8,0.8v6c0,0.4,0.3,0.8,0.8,0.8h6c0.4,0,0.8-0.3,0.8-0.8V11.2l1.7,1.7 c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.4-0.1,0.5-0.2C24.1,12.7,24.1,12.2,23.8,11.9z M17.3,4.9h2.3v2.7l-2.3-2.2V4.9z M19.5,21.4H15 v-5.3c0-1.2-1-2.3-2.3-2.3h-1.5c-1.2,0-2.3,1-2.3,2.3v5.3H4.5V9.8l7-7c0.3-0.3,0.8-0.3,1.1,0l7,7V21.4z"></path>
  </svg>

export const ArrowLeft = () =>
  <svg id="mt-arrow-back" viewBox="0 0 24 24">
    <path fill="currentcolor" d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"></path>
    <path fill="none" d="M0 0h24v24H0z"></path>
  </svg>

export const ArrowRight = () =>
  <svg id="mt-arrow-forward" viewBox="0 0 24 24">
    <path fill="currentcolor" d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"></path>
    <path fill="none" d="M0 0h24v24H0z"></path>
  </svg>

export const ArrowRightIOS = () =>
  <svg xmlns="http://www.w3.org/2000/svg" id="mt-arrow-forward-ios" viewBox="0 0 24 24">
    <path fill="currentcolor"
          d="M7.38 21.01c.49.49 1.28.49 1.77 0l8.31-8.31c.39-.39.39-1.02 0-1.41L9.15 2.98c-.49-.49-1.28-.49-1.77 0s-.49 1.28 0 1.77L14.62 12l-7.25 7.25c-.48.48-.48 1.28.01 1.76z" />
    <path fill="none" d="M0 0h24v24H0z" />
  </svg>

export const WeatherNightClear = () =>
  <svg xmlns="http://www.w3.org/2000/svg" id="weather-night-clear" viewBox="0 0 24 24">
    <path fill="currentcolor"
          d="M21.9,14.8c-1.3,0.6-2.7,0.9-4.1,0.9c-2.7,0-5.1-1-7-2.9c-1.9-1.9-2.9-4.4-2.9-7c0-1,0.1-2,0.4-2.9C8.4,2.6,8.3,2.2,8,2 C7.8,1.8,7.5,1.8,7.2,1.9c-5.8,2.7-8.3,9.6-5.7,15.4c1.3,2.8,3.6,4.9,6.5,6c1.3,0.5,2.7,0.7,4,0.7c1.6,0,3.3-0.4,4.8-1.1 c3-1.4,5.3-4,6.2-7.1c0.1-0.3,0-0.7-0.2-0.9C22.6,14.7,22.2,14.7,21.9,14.8z M16.2,21.4c-2.4,1.1-5.1,1.2-7.6,0.3 C6.1,20.8,4.1,19,3,16.6C1,12.1,2.5,7,6.3,4.3c-0.1,0.5-0.1,1-0.1,1.5c0,3.1,1.2,6,3.4,8.2c2.2,2.2,5.1,3.4,8.2,3.4 c1,0,2-0.1,3-0.4C19.8,18.9,18.2,20.5,16.2,21.4z" />
    <path fill="currentcolor"
          d="M12,4.1h1.7v1.7c0,0.5,0.4,0.8,0.8,0.8s0.8-0.4,0.8-0.8V4.1H17c0.5,0,0.8-0.4,0.8-0.8S17.4,2.5,17,2.5h-1.7V0.8 c0-0.5-0.4-0.8-0.8-0.8s-0.8,0.4-0.8,0.8v1.7H12c-0.5,0-0.8,0.4-0.8,0.8S11.5,4.1,12,4.1z" />
    <path fill="currentcolor"
          d="M22.7,7.5h-0.8V6.6c0-0.5-0.4-0.8-0.8-0.8s-0.8,0.4-0.8,0.8v0.8h-0.8c-0.5,0-0.8,0.4-0.8,0.8s0.4,0.8,0.8,0.8h0.8v0.8 c0,0.5,0.4,0.8,0.8,0.8s0.8-0.4,0.8-0.8V9.1h0.8c0.5,0,0.8-0.4,0.8-0.8S23.2,7.5,22.7,7.5z" />
  </svg>

export const Users = () =>
  <svg xmlns="http://www.w3.org/2000/svg" id="users" viewBox="0 0 24 24">
    <path fill="currentcolor"
          d="M7.5,6c0-1.7-1.3-3-3-3s-3,1.3-3,3s1.3,3,3,3S7.5,7.7,7.5,6z M4.5,7.5C3.7,7.5,3,6.8,3,6s0.7-1.5,1.5-1.5S6,5.2,6,6 S5.3,7.5,4.5,7.5z" />
    <path fill="currentcolor"
          d="M6,21H3.7L3,15.7c0-0.4-0.4-0.7-0.7-0.7H1.5v-1.5c0-1.7,1.3-3,3-3c0.4,0,0.8-0.3,0.8-0.8S4.9,9,4.5,9C2,9,0,11,0,13.5v2.3 c0,0.4,0.3,0.8,0.8,0.8h0.8l0.7,5.3c0,0.4,0.4,0.7,0.7,0.7h3c0.4,0,0.8-0.3,0.8-0.8S6.4,21,6,21z" />
    <path fill="currentcolor"
          d="M22.5,6c0-1.7-1.3-3-3-3s-3,1.3-3,3s1.3,3,3,3S22.5,7.7,22.5,6z M19.5,7.5C18.7,7.5,18,6.8,18,6s0.7-1.5,1.5-1.5 S21,5.2,21,6S20.3,7.5,19.5,7.5z" />
    <path fill="currentcolor"
          d="M19.5,9c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8c1.7,0,3,1.3,3,3V15h-0.8c-0.4,0-0.7,0.3-0.7,0.7L20.3,21H18 c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h3c0.4,0,0.7-0.3,0.7-0.7l0.7-5.3h0.8c0.4,0,0.8-0.3,0.8-0.8v-2.3C24,11,22,9,19.5,9z" />
    <path fill="currentcolor"
          d="M15.8,3.8C15.8,1.7,14.1,0,12,0C9.9,0,8.3,1.7,8.3,3.8S9.9,7.5,12,7.5C14.1,7.5,15.8,5.8,15.8,3.8z M12,6 c-1.2,0-2.3-1-2.3-2.3s1-2.3,2.3-2.3c1.2,0,2.3,1,2.3,2.3S13.2,6,12,6z" />
    <path fill="currentcolor"
          d="M18,15.8v-2.3c0-3.3-2.7-6-6-6s-6,2.7-6,6v2.3c0,0.4,0.3,0.8,0.8,0.8h1.6L9,23.3C9,23.7,9.4,24,9.8,24h4.5 c0.4,0,0.7-0.3,0.7-0.7l0.7-6.8h1.6C17.7,16.5,18,16.2,18,15.8z M16.5,15H15c-0.4,0-0.7,0.3-0.7,0.7l-0.7,6.8h-3.1l-0.7-6.8 C9.7,15.3,9.4,15,9,15H7.5v-1.5C7.5,11,9.5,9,12,9s4.5,2,4.5,4.5V15z" />
  </svg>