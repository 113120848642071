import React, {FC, useContext, useMemo, useState} from "react"

// Hooks
import {useAssumeUser} from "../hooks/useAssumeUser"

// Components
import {Loader, Select, SelectItem} from "@mantine/core"
import {UserContext} from "../usercontext"
import {Navigate} from "react-router-dom"
import {House as HouseIcon} from "./../components/icons"
import {TranslationContext} from "../translationcontext"

// Lib
import {debounce} from "@material-ui/core"

// API
import {AdminSearchOwners} from "../api"

// Types
import {AssumeUserProps} from "./interfaces"
import {UserProfile} from "../generated_api"

export const AssumeUser: FC<AssumeUserProps> = ({LoginSuccessful}) => {
    const {isBVUser, user, logout: userLogout} = useContext(UserContext)
    const translations = useContext(TranslationContext)

    const [selectedOwner, setSelectedOwner] = useState<string | undefined>()
    const [foundOwners, setFoundOwners] = useState<UserProfile[]>([])

    const {mutateAsync: assumeUser, isLoading: assumeLoading} = useAssumeUser()

    const searchOwners = async (query: string) => {
        const foundOwners = await AdminSearchOwners(query)
        setFoundOwners(foundOwners)
    }

    const debouncedSearch = useMemo(() => {
        return debounce(searchOwners, 300)
    }, [])

    const selectOptions: SelectItem[] = foundOwners.map(u => ({
        value: u.id!,
        label: u.accountInfo?.email!,
        group: u.accountInfo?.companyName!
    }))

    const assume = async () => {
        if (selectedOwner !== undefined) {
            const result = await assumeUser(selectedOwner)
            LoginSuccessful(result)
        }
    }

    const logout = () => {
        userLogout()
        return <Navigate to={"/adminlogin"}/>
    }

    if (user === undefined) {
        return <Navigate to={"/adminlogin"}/>
    }

    if (isBVUser && user?.id !== null && user?.id !== undefined) {
        return <Navigate to={"/"}/>
    }

    return (
        <>
            <div className="bv-login">
                <div style={{display: "inline-grid", justifyItems: "center"}}>
                    <div className="bv-icon" style={{
                        height: "68px",
                        width: "68px",
                        padding: "18px",
                        background: "#517980",
                        borderRadius: "50%",
                        color: "white"
                    }}>
                        <HouseIcon/>
                    </div>

                    <h1>
                        {translations.login?.assumeUser}
                    </h1>

                    <Select
                        sx={{
                            width: "100%",
                            height: 43,
                            minWidth: 312,
                            "input": {
                                height: 43
                            }
                        }}
                        clearable
                        searchable
                        placeholder="Search & select a user"
                        data={selectOptions}
                        onSearchChange={debouncedSearch}
                        onChange={(e) => setSelectedOwner(e || undefined)}
                        onKeyDown={(e) => e.key === "Enter" && selectedOwner && assume()}
                    />


                    <div style={{marginTop: "20px"}}>
                        <button
                            style={{background: "white", marginRight: "5px", width: "90px"}}
                            onClick={logout}
                            className="btn btn-lg">
                            {translations.common?.logOut}
                        </button>
                        <button
                            style={{background: "white", marginLeft: "5px", width: "90px"}}
                            disabled={selectedOwner === undefined}
                            onClick={assume}
                            className="btn btn-lg">
                            {assumeLoading ? <Loader style={{height: 16}}/> :
                                <span>{translations.login?.continue}</span>}
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}