import {useMutation, useQueryClient} from "@tanstack/react-query";
import {NewReservationBlock} from "../api";

export const useCreateNewBlock = () => {
    const queryClient = useQueryClient()

    return useMutation(["createNewBlock"],
        async (
            {
                from,
                to,
                guestRoomId,
            }: {
                from: Date,
                to: Date,
                guestRoomId: string,
            }) => {
            return NewReservationBlock(from, to, guestRoomId)
        },
        {
            onSuccess: (_, {guestRoomId}) => {
                setTimeout(() => {
                    queryClient.invalidateQueries({queryKey: ["reservationsAndRestrictions", guestRoomId]});
                }, 200)
            }
        })
}