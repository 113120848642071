import React, { FC, useContext, useState } from "react"

// Hooks
import { useDateTimeFormat } from "../hooks/useDateTimeFormat"
import { useCancelReservation } from "../hooks/useCancelReservation"

// Components
import { useMediaQuery } from "react-responsive"
import { Button, Drawer, Loader } from "@mantine/core"
import { TranslationContext } from "../translationcontext"
import { UserContext } from "../usercontext"

// Lib
import { currencyFormatter, getDaysBetweenDates } from "../helpers"

// Types
import { BookingDetailsModalProps } from "./interfaces"
import { OwnerPortalGuest, OwnerPortalGuestType } from "../generated_api"


export const BookingDetailsModal: FC<BookingDetailsModalProps> = (
  {
    booking, imageUrl, showDrawer,
    onClose, onReservationCancelled
  }) => {
  const translations = useContext(TranslationContext)
  const userContext = useContext(UserContext)
  const [isCanceling, setIsCanceling] = useState(false)
  const [showConfirmCancelButtons, setShowConfirmCancelButton] = useState(false)

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 500px)" })
  const { languageCulture } = useDateTimeFormat()
  const { mutateAsync: cancelReservation } = useCancelReservation()

  const isBVWebBooking = booking?.isBVWebBookingChannel
  const isOwnersBooking = booking?.channel?.toLocaleLowerCase() === "ownersportal"
  const isExternalBooking = isBVWebBooking === false && !isOwnersBooking
  const hasDeparturePassed = booking?.departure && booking.departure < new Date()
  const isCancelled = booking?.cancelled

  const arrivalDepartureString = () => {
    if (!booking) return ""
    const nightsCount = getDaysBetweenDates(booking?.arrival!, booking?.departure!).length
    return booking.arrival && booking.departure
      ? `${booking.arrival.toDateString()} - ${booking.departure.toDateString()}, ${nightsCount} ${
        nightsCount > 1 ? translations.common?.nights : translations.common?.night
      }`
      : ""
  }

  const guestsString = () => {
    const adultsCount = booking?.guests?.filter(
      (guest: OwnerPortalGuest) => guest.typeOfGuest === OwnerPortalGuestType.Adult
    ).length ?? 0
    const children = booking?.guests?.filter(
      (guest) => guest.typeOfGuest === OwnerPortalGuestType.Child
    )
    return `${adultsCount} ${translations.common?.adult}${
      children?.length
        ? `, ${children.length} ${translations.common?.child} (${children.map((child) => child.age).join(", ")} ${translations.common?.years})`
        : ""
    }`
  }

  const CancelReservation = async () => {
    setIsCanceling(true)
    setShowConfirmCancelButton(false)
    const result = await cancelReservation({
      bookingCode: booking?.bookingCode!,
      roomUnitId: booking?.roomUnitId!,
      roomTypeId: booking?.roomTypeId!
    })
    setIsCanceling(false)
    onReservationCancelled(result === true)
  }

  return (
    <>
      <Drawer
        position="right"
        className="booking-drawer"
        size={isTabletOrMobile ? "100%" : "400px"}
        opened={showDrawer || isCanceling}
        onClose={() => onClose()}
      >
        <div className="modal-content">
          <div className="vg-panel">
            <div className="vg-panel-header">
              <div className="vg-panel-title">
                <div className="small text-muted">{translations.common?.booking} #</div>
                <h3>{booking?.bookingCode}</h3>
              </div>
              <button
                type="button"
                disabled={isCanceling}
                className="close"
                onClick={() => onClose()}
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="vg-panel-content">
              <div className="vg-form-section">
                {!booking?.isCustomerDataHidden && (
                  <>
                    <div className="small text-muted">{translations.common?.customerName}:</div>
                    <div>{booking?.customerName}</div>
                  </>
                )}
                <div className="small text-muted" style={{ marginTop: "5px" }}>
                  {translations.common?.arrival} / {translations.common?.departure} / {translations.common?.noOfNights}
                </div>
                <div>{arrivalDepartureString()}</div>

                <div className="small text-muted" style={{ marginTop: "5px" }}>
                  {translations.common?.guests}
                </div>
                <div>{guestsString()}</div>

                <div className="vg-simple-grid align-middle vg-gutter">
                  <div className="vg-fullwidth">
                    <div className="small text-muted" style={{ marginTop: "5px" }}>
                      {translations.common?.bookingChannel}
                    </div>
                    {isBVWebBooking && <div>{booking?.hotelname}</div>}
                    {isOwnersBooking && <div>{translations.common?.owner}</div>}
                    {isExternalBooking && <div>{booking?.channel}</div>}
                  </div>
                  {booking && (
                    <div
                      className="bv-channel"
                      style={{
                        backgroundImage: `url(${booking.channelLogoImageUrl || `${imageUrl}${booking.channelLogoImageId}.png`})`
                      }}
                    ></div>
                  )}
                </div>
              </div>

              <div className="vg-form-section">
                <table className="table-text vg-fullwidth">
                  <tbody>
                  <tr>
                    <td className="text-muted">
                      <small>{translations.common?.salesAmountIncVAT}:</small>
                    </td>
                    <td className="text-right">
                      {booking?.currency && currencyFormatter(booking.currency, booking.salesAmountIncVAT!, languageCulture)}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-muted">
                      <small>{translations.common?.discount}:</small>
                    </td>
                    <td className="text-right">
                      {booking?.currency && currencyFormatter(booking.currency, booking.discount!, languageCulture)}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-muted">
                      <small>{translations.common?.channelCost}:</small>
                    </td>
                    <td className="text-right">
                      {booking?.currency && currencyFormatter(booking.currency, booking.channelCost!, languageCulture)}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-muted">
                      <small>{translations.common?.cleaning}:</small>
                    </td>
                    <td className="text-right">
                      {booking?.currency && currencyFormatter(booking.currency, booking.cleaningCost!, languageCulture)}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-muted">
                      <small>{translations.common?.subTotal}:</small>
                    </td>
                    <td className="text-right">
                      {booking?.currency && currencyFormatter(booking.currency, booking.subTotal!, languageCulture)}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ height: "10px" }}></td>
                    <td></td>
                  </tr>
                  {!userContext.hidePayDetails && booking?.ownerCommissionAmount !== undefined && (
                    <>
                      <tr>
                        <td className="text-muted">
                          <small><b>{translations.common?.operatorFee}:</b></small>
                        </td>
                        <td className="text-right">
                          <b>{booking?.currency && currencyFormatter(booking.currency, booking.ownerCommissionAmount!, languageCulture)}</b>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-muted">
                          <small><b>{translations.common?.ownerRevenue}:</b></small>
                        </td>
                        <td className="text-right">
                          <b>
                            {booking?.currency &&
                              currencyFormatter(
                                booking.currency,
                                booking.salesAmountIncVAT! -
                                booking.cleaningCost! -
                                booking.channelCost! -
                                booking.ownerCommissionAmount!,
                                languageCulture
                              )}
                          </b>
                        </td>
                      </tr>
                    </>
                  )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {showConfirmCancelButtons && (
            <div style={{
              textAlign: "center",
              display: "grid",
              gridAutoFlow: "column",
              gridGap: "10px",
              margin: "10px"
            }}>
              <Button onClick={CancelReservation}>{translations.common?.yesContinue}</Button>
              <Button variant="outline-secondary" onClick={() => setShowConfirmCancelButton(false)}>
                {translations.common?.noCancel}
              </Button>
            </div>
          )}

          {!showConfirmCancelButtons && isOwnersBooking && !booking?.checkedIn && (
            <Button
              disabled={isCancelled || isCanceling || hasDeparturePassed || !!userContext.user?.bvUser}
              onClick={() => setShowConfirmCancelButton(true)}
              style={{ width: "80%", height: "40px", alignSelf: "center" }}
            >
              {isCanceling ? (
                <Loader size="sm" role="status" aria-hidden="true"
                        style={{ marginRight: "5px", verticalAlign: "middle" }} />
              ) : (
                <span>{isCancelled ? translations.common?.cancelled : translations.common?.cancelReservation}</span>
              )}
            </Button>
          )}
        </div>
      </Drawer>
    </>
  )
}