import React, {FC, Suspense, useContext, useEffect, useState} from "react"

// Components
import {Route, Routes} from "react-router-dom"
import {Bookings} from "./bookings"
import {TranslationContext} from "../translationcontext"
import {Availability} from "./availability"
import {Financial} from "./financial"
import {Contact} from "./contact"
import {Rates} from "./rates"
import {Profile} from "./profile"
import {MyObjects} from "./my_objects"
import {Home} from "./home"
import {TopBar} from "../components/topbar"
import {UserContext} from "../usercontext"
import {Loader} from "@mantine/core"
import {ErrorBoundary} from "../components/error_boundary"

// API
import {GetImageUrls, GetOwnersObjects, GetTranslations} from "../api"

// Types
import {ImageUrlsResult, LandingPageProps} from "./interfaces"
import {GuestRoomInfo} from "../generated_api"


export const LandingPage: FC<LandingPageProps> = ({UpdateTranslations}) => {
    let userContext = useContext(UserContext)
    let translations = useContext(TranslationContext)
    let [translationsDone, setTranslationsDone] = useState(false)
    let [isLoading, setIsLoading] = useState(true)
    let [selectedObject, setSelectedObject] = useState<undefined | string>()
    let [ownersObjects, setOwnersObjects] = useState<GuestRoomInfo[]>([])
    let [imageUrls, setImageUrls] = useState<ImageUrlsResult | undefined | null>(undefined)

    useEffect(() => {
        if (translations.langId !== userContext.user?.languageId) {
            GetTranslations().then(result => {
                if (result instanceof Error) {
                    setTranslationsDone(true)
                    throw result
                } else {
                    UpdateTranslations(result)
                    setTranslationsDone(true)
                }
            })
        } else {
            setTranslationsDone(true)
        }
    }, [UpdateTranslations, translations.langId, userContext.user])

    useEffect(() => {
        GetImageUrls().then(result => {
            if (result instanceof Error) {
                setImageUrls(null)
            } else {
                setImageUrls({header: result.header, logo: result.logo, profile: result.profile})
            }
        })
    }, [])

    useEffect(() => {
        GetOwnersObjects().then((r: GuestRoomInfo[]) => {
            const sortedObjects = r.sort((a, b) => a?.name?.localeCompare(b?.name!)!)

            setOwnersObjects(sortedObjects)
            if (sortedObjects?.length > 0) {
                setSelectedObject(sortedObjects[0].id)
            }
            setIsLoading(false)
        })
    }, [])

    if (!translationsDone || isLoading) {
        return <div style={{display: "grid", alignContent: "center", height: "100%"}}>
            <Loader size="sm" role="status" aria-hidden="true" style={{
                height: "50px",
                width: "50px",
                marginRight: "5px",
                justifySelf: "center",
                verticalAlign: "middle",
                color: "#0f464a"
            }}/>
        </div>
    }

    return (
        <ErrorBoundary>
            <TranslationContext.Provider value={translations}>
                <TopBar ImageUrls={imageUrls}/>
                <Routes>
                    <Route path={"/bookings"} element={
                        <Bookings ownersObjects={ownersObjects} selectedObject={selectedObject}
                                  onSelectedObjectChanged={(newObjectId) => setSelectedObject(newObjectId)}/>
                    }/>
                    <Route path={"/availability"} element={
                        <Availability ownersObjects={ownersObjects} selectedObject={selectedObject}
                                      onSelectedObjectChanged={(newObjectId) => setSelectedObject(newObjectId)}/>
                    }/>
                    {userContext.user?.showRatesTab && (
                        <Route path={"/rates"} element={
                            <Rates ownersObjects={ownersObjects} selectedObject={selectedObject}
                                   onSelectedObjectChanged={(newObjectId) => setSelectedObject(newObjectId)}/>
                        }/>
                    )}
                    <Route path={"/financial"} element={
                        <Financial ownersObjects={ownersObjects} selectedObject={selectedObject}
                                   onSelectedObjectChanged={(newObjectId) => setSelectedObject(newObjectId)}/>
                    }/>
                    <Route path={"/myobjects"} element={<MyObjects/>}/>
                    <Route path={"/contact"} element={
                        <Suspense fallback={<Loader/>}>
                            <Contact/>
                        </Suspense>}
                    />
                    <Route path={"/profile"} element={<Profile/>}/>
                    <Route path="/" element={<Home ProfileImageUrl={imageUrls?.profile}/>}/>
                </Routes>
                <div className="bv-footer">
                    <div className="bv-inner">
                        <a href="https://www.bookvisit.com" className="text-muted small" target="_blank"
                           rel="noopener noreferrer">
                            <span> Powered by BookVisit, </span>
                            <span> part of Visit Group AB</span>
                        </a>
                    </div>
                </div>
            </TranslationContext.Provider>
        </ErrorBoundary>
    )
}