import React, { FC, useContext } from "react"

// Components
import { Button } from "@mantine/core"
import { UserContext } from "../usercontext"
import { TranslationContext } from "../translationcontext"

// Types
import { PaymentInfoFormProps } from "./interfaces"


export const PaymentInfoForm: FC<PaymentInfoFormProps> = (
  {
    savePaymentInfo, paymentInfo, updatePaymentInfo,
    loading
  }) => {
  const {isBVUser, hasFullAccess} = useContext(UserContext)
  const translations = useContext(TranslationContext)

  if (paymentInfo === undefined) return null

  const handleChange = (event: any) => {
    updatePaymentInfo({
      ...paymentInfo,
      [event.currentTarget.name]: event.currentTarget.value
    })
  }

  return (
    <div>
      <div className="vg-panel vg-panel-lg">
        <div className="vg-panel-content">
          <div className="vg-form-section">
            <div className="vg-form-section-title">
              {translations.common?.paymentInformation}
              <p className="text-muted">
                {translations.common?.paymentInformationSubtitle}
              </p>
            </div>
            <div className="vg-form-grid vg-grid-2">
              <div className="vg-form-group">
                <label>{translations.common?.bank}</label>
                <div>
                  <input
                    className="form-control form-control-lg"
                    disabled={loading}
                    type="text"
                    name="bank"
                    onBlur={handleChange}
                    defaultValue={paymentInfo.bank}
                  />
                </div>
              </div>
              <div className="vg-form-group">
                <label>{translations.common?.accountNumber}</label>
                <div>
                  <input
                    className="form-control form-control-lg"
                    disabled={loading}
                    type="text"
                    name="accountNumber"
                    onBlur={handleChange}
                    defaultValue={paymentInfo.accountNumber}
                  />
                </div>
              </div>
              <div className="vg-form-group">
                <label>{translations.common?.bicCode}</label>
                <div>
                  <input
                    className="form-control form-control-lg"
                    disabled={loading}
                    type="text"
                    name="bic"
                    onBlur={handleChange}
                    defaultValue={paymentInfo.bic}
                  />
                </div>
              </div>
              <div className="vg-form-group">
                <label>{translations.common?.swiftCode}</label>
                <div>
                  <input
                    className="form-control form-control-lg"
                    disabled={loading}
                    type="text"
                    name="swiftCode"
                    onBlur={handleChange}
                    defaultValue={paymentInfo.swiftCode}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="vg-panel-footer">
          <Button
            disabled={loading || isBVUser && !hasFullAccess}
            onClick={savePaymentInfo}
            className="btn btn-lg btn-primary btn-rounded">
            {translations.common?.saveChanges}
          </Button>
        </div>
      </div>
    </div>
  )
}
