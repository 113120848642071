import React, {FC, useContext, useEffect, useMemo, useState} from "react"

// Hooks
import {useDateTimeFormat} from "../hooks/useDateTimeFormat";

// Components
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core"
import {Button as MantineButton, Loader} from "@mantine/core"
import {TranslationContext} from "../translationcontext"
import {useMediaQuery} from "react-responsive"

// API
// CSS
import "react-datepicker/dist/react-datepicker.css"

// Types
import {RemoveBlockModalProps} from "./interfaces"
import {useRemoveReservationBlock} from "../hooks/useRemoveReservationBlock";

const RemoveBlockModal: FC<RemoveBlockModalProps> = (
    {
        selectedDate: selectedDateFromProps, show, onClose, guestRoomId
    }) => {
    const  translations = useContext(TranslationContext)
    const [selectedFromDate, setSelectedFromDate] = useState<string | undefined>()
    const isTabletOrMobile = useMediaQuery({query: "(max-width: 500px)"})
    const {languageCulture} = useDateTimeFormat()

    const {mutateAsync: removeReservationBlock, isLoading} = useRemoveReservationBlock()

    const SaveBlock = async () => {
        let form: any = document.getElementById("newblockform")
        if (!form.reportValidity()) {
            return
        }


        let from = form.fromdate.value
        let to = form.todate.value

        let result = await removeReservationBlock({from, to, guestRoomId})
        if (result === true) {
            onClose(true)
        }

    }

    const selectedDate = useMemo(() => selectedDateFromProps?.toLocaleDateString(languageCulture), [selectedDateFromProps])

    useEffect(() => {
        if (show) {
            setSelectedFromDate(selectedDate)
        }
    }, [show, selectedDate])

    return (
        <Dialog open={show} fullScreen={isTabletOrMobile} disableEscapeKeyDown={true}
                onClose={() => onClose()}
                aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{translations.availability?.removeReservationBlock}</DialogTitle>
            <DialogContent style={{paddingBottom: "20px"}}>
                <DialogContentText id="alert-dialog-description">
                    {translations.availability?.removeReservationBlockDescription}
                </DialogContentText>
                <form id="newblockform">
                    <br/>
                    <p>{translations.availability?.from}:</p>
                    <input
                        required
                        className="month-picker"
                        onChange={(e) => setSelectedFromDate(e.target.value)}
                        style={{
                            background: "transparent", height: "40px", borderRadius: "3px",
                            border: "1px solid #cfcecc", paddingLeft: "1rem", paddingRight: "1rem", width: "100%"
                        }}
                        type="date" id="start"
                        name="fromdate"
                        min={new Date().toLocaleDateString(languageCulture)}
                        defaultValue={selectedFromDate ?? ""}
                    />

                    <br/><br/>
                    <p>{translations.availability?.to}:</p>
                    <input
                        required className="month-picker"
                        style={{
                            background: "transparent",
                            height: "40px",
                            borderRadius: "3px",
                            border: "1px solid #cfcecc",
                            paddingLeft: "1rem",
                            paddingRight: "1rem",
                            width: "100%"
                        }}
                        type="date" id="end" name="todate"
                        min={new Date(`'${selectedFromDate}'`).toLocaleDateString(languageCulture)}
                        defaultValue={selectedFromDate ?? ""} key={selectedFromDate}/>
                    <br/>
                    <br/>

                </form>
            </DialogContent>
            <DialogActions style={{paddingLeft: "24px", paddingRight: "24px", paddingBottom: "15px"}}>
                <MantineButton disabled={isLoading} onClick={() => onClose()} variant="secondary"
                               style={{minWidth: "60px"}}>
                    {translations.common?.cancel}
                </MantineButton>
                <MantineButton
                    onClick={SaveBlock}
                    disabled={isLoading} style={{minWidth: "80px"}}>
                    {isLoading && <Loader size="sm" role="status" aria-hidden="true"
                                        style={{marginRight: "5px", verticalAlign: "middle"}}/>}
                    {isLoading && translations.common?.saving}
                    {!isLoading && translations.common?.save}
                </MantineButton>
            </DialogActions>
        </Dialog>
    )
}

export default RemoveBlockModal