import React, { useContext, useEffect, useState } from "react"
import { Navigate } from "react-router-dom"
import { UserContext } from "./usercontext"

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export function PrivateRoute({ children, ...rest }) {
  let userContext = useContext(UserContext)
  let [logoutLink, setLogoutLink] = useState("/login")

  useEffect(() => {
    if (userContext.user !== undefined) {
      if (userContext.user.authedByDomainName) {
        setLogoutLink(`/login/${userContext.user.authedByDomainName}`)
      } else {
        setLogoutLink(`/login/${userContext.user.organizationId}`)
      }
    }
  }, [userContext])

  if (userContext.user === undefined) {
    return <Navigate to={logoutLink} />
  } else if (userContext.user.id === null) {
    return <Navigate to="/assumeuser" />
  } else {
    return children
  }
}