/**
 * Configuration class needed in base class.
 * The config is provided to the API client at initialization time.
 * API clients inherit from #AuthorizedApiBase and provide the config.
 */
export class IConfig {
    /**
     * Returns a valid value for the Authorization header.
     * Used to dynamically inject the current auth header.
     */
    getAuthorization?: () => string
    getAuthorizationAsync?: () => Promise<string>
}

export class AuthorizedApiBase {
    private readonly config: IConfig;

    protected constructor(config: IConfig) {
        this.config = config;
    }

    protected transformOptions = async (options: RequestInit): Promise<RequestInit> => {
        options.headers = this.config.getAuthorizationAsync ? {
            ...options.headers,
            Authorization: await this.config.getAuthorizationAsync!(),
        } : {
            ...options.headers,
            Authorization: this.config.getAuthorization!(),
        };

        return Promise.resolve(options);
    };
}