import React, { FC, useContext, useState } from "react"

// Components
import { Navigate } from "react-router-dom"
import { TranslationContext } from "../translationcontext"
import { Loader } from "@mantine/core"
import { UserContext } from "../usercontext"
import { House as HouseIcon, Lock as LockIcon, Person as PersonIcon } from "./../components/icons"

// Lib
import { isAdminUser } from "../helpers"
import * as API from "./../api"

// Types
import { AdminLoginProps } from "./interfaces"

export const AdminLogin:FC<AdminLoginProps> = ({ LoginSuccessful }) => {
  const {isBVUser} = useContext(UserContext)
  const translations = useContext(TranslationContext)

  const [state, setState] = useState<{ loading: boolean, error: string | undefined }>({
    loading: false,
    error: undefined
  })

  const login = async (event: any) => {
    event.preventDefault()

    setState({ loading: true, error: undefined })
    let user = event.target.username.value
    let pass = event.target.password.value

    let result = await API.AdminLogin(user, pass)
    if (result instanceof Error) {
      setState({ loading: false, error: result.message })
    } else if (isAdminUser(result)) {
      localStorage.setItem("bv_op_userinfo", JSON.stringify(result))
      LoginSuccessful(result)
    }
  }

  if (isBVUser) {
    return <Navigate to={"/assumeuser"} />
  }

  return (
    <>
      <div className="bv-login">

        <div style={{ display: "inline-grid", justifyItems: "center" }}>
          <div className="bv-icon" style={{
            height: "68px",
            width: "68px",
            padding: "18px",
            background: "#517980",
            borderRadius: "50%",
            color: "white"
          }}>
            <HouseIcon />
          </div>
          <h1>
            {translations.login?.adminLogin}
          </h1>
          <form onSubmit={login} className="bv-login-form">
            <div className="vg-form-control-icon">
              <input autoComplete="username" required disabled={state.loading} name="username"
                     className="form-control form-control-lg" type="text" placeholder={translations.login?.username} />
              <PersonIcon />
            </div>
            <div className="vg-form-control-icon">
              <input autoComplete="current-password" required disabled={state.loading} name="password"
                     className="form-control form-control-lg" type="password"
                     placeholder={translations.login?.password} />
              <LockIcon />
            </div>
            <button disabled={state.loading} type="submit" className="btn btn-lg">
              {state.loading && <Loader size="sm" role="status" aria-hidden="true"
                                        style={{ marginRight: "5px", verticalAlign: "middle" }} />}
              {(state.loading && translations.login?.signingIn) || translations.login?.continue}
            </button>
            {state.error && <p style={{ color: "orangered", position: "absolute" }}>{state.error}</p>}
          </form>
          <div className="bv-powered">
            <a href="https://www.bookvisit.com" className="small" target="_blank" rel="noreferrer noopener">
              <span>Powered by BookVisit,</span>
              <span>part of Visit Group AB</span>
            </a>
          </div>
        </div>
      </div>
    </>
  )
}