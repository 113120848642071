import React, { FC, useContext, useState } from "react"

// Components
import { Link, NavLink } from "react-router-dom"
import { Button, Menu } from "@mantine/core"
import { UserContext } from "../usercontext"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faTimes, faUser } from "@fortawesome/free-solid-svg-icons"
import { TranslationContext } from "../translationcontext"

// Types
import { TopBarProps } from "./interfaces"

export const TopBar: FC<TopBarProps> = ({ ImageUrls }) => {
  const [expanded, setExpanded] = useState(false)
  const [showProfileMenu, setShowProfileMenu] = useState(false)

  const {user, logout, updateContext} = useContext(UserContext)
  const translations = useContext(TranslationContext)

  const unassumeUser = () => {
    updateContext!({ ...user!, id: undefined })
  }

  return (
    <>
      {expanded && <div className="bv-menu-mobile" style={{
        background: "rgba(0,0,0,0.9)",
        height: "100%",
        width: "100%",
        zIndex: 25,
        position: "absolute",
        top: 0
      }}>

        <div style={{ color: "white" }}>
          <NavLink onClick={() => setExpanded(false)} to="/">{translations.common?.start}</NavLink>
          <NavLink onClick={() => setExpanded(false)} to="/bookings">{translations.common?.bookings}</NavLink>
          <NavLink onClick={() => setExpanded(false)}
                   to="/availability">{translations.common?.availabilityCalendar}</NavLink>
          {user?.showRatesTab &&
            <NavLink onClick={() => setExpanded(false)} to="/rates">{translations.rates?.rates}</NavLink>
          }
          <NavLink onClick={() => setExpanded(false)} to="/financial">{translations.common?.financialReport}</NavLink>
          <NavLink onClick={() => setExpanded(false)} to="/profile">{translations.common?.myProfile}</NavLink>
          <NavLink onClick={() => setExpanded(false)} to="/myobjects">{translations.common?.infoAboutMyObjects}</NavLink>
          <NavLink onClick={() => setExpanded(false)} to="/contact">{translations.common?.contact}</NavLink>
          {(user?.bvUser !== undefined && user?.bvUser !== null) &&
            <NavLink onClick={unassumeUser} to="/assumeuser">Unassume user</NavLink>}
          <NavLink onClick={() => logout()} to="/">{translations.common?.logOut}</NavLink>
        </div>

        <button className="bv-close" onClick={() => setExpanded(false)}
                style={{ background: "transparent", border: 0 }}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>}


      <div className="bv-header">
        <div className="bv-cover">
          <div style={ImageUrls?.header ? { backgroundImage: `url(${ImageUrls?.header})` } : {}}></div>
        </div>


        <div className="bv-inner">
          <Link to="/" className="bv-logo">
            {ImageUrls?.logo && <img alt="" src={ImageUrls.logo} />}
          </Link>

          <Button className="bv-menu-trigger" onClick={() => setExpanded(true)}
                  style={{ width: "40px", height: "40px", padding: "10px", borderRadius: "50%" }}>
            <FontAwesomeIcon icon={faBars} />
          </Button>

          <div className="bv-menu">
            <MenuItem title={translations.common?.start!} to="/" />
            <MenuItem title={translations.common?.bookings!} to="/bookings" />
            <MenuItem title={translations.common?.availability!} to="/availability" />
            {user?.showRatesTab && <MenuItem title={translations.rates?.rates!} to="/rates" />}
            <MenuItem title={translations.common?.financial!} to="/financial" />
            <MenuItem title={translations.common?.myObjects!} to="/myobjects" />
          </div>

          <Menu onChange={(isOpen: boolean) => setShowProfileMenu(isOpen)}
                id="profile-dd" opened={showProfileMenu} position="bottom-end" withinPortal
                styles={{
                  dropdown: { zIndex: 21 }
                }}>
            {/*TODO: alignRight is missing id="profile-dd" alignRight show={showProfileMenu}>*/}
            <Menu.Target>
              <div className="bv-secondary" id="Menu-basic" onClick={() => setShowProfileMenu(!showProfileMenu)}>
                <button className="dropdown-toggle btn btn-success" style={{ background: "transparent", border: 0 }}>
                  <div className="profile-button"
                       id="profile-dd-div">
                    <FontAwesomeIcon icon={faUser} />
                  </div>
                </button>
              </div>
            </Menu.Target>

            <Menu.Dropdown>
              {(user?.firstName || user?.lastName) && (
                <Menu.Item style={{ color: "#999693", fontWeight: 600 }}
                           disabled>{user?.firstName} {user?.lastName}
                </Menu.Item>
              )}
              <Menu.Item component={NavLink} onClick={() => setShowProfileMenu(false)} className="Menu-item"
                         to="/profile">{translations.common?.myProfile}
              </Menu.Item>
              <Menu.Item component={NavLink} onClick={() => setShowProfileMenu(false)} className="Menu-item"
                         to="/contact">{translations.common?.contact}
              </Menu.Item>

              <Menu.Divider />

              <Menu.Item onClick={() => logout()}>{translations.common?.logOut}</Menu.Item>

              {(user?.bvUser !== undefined && user?.bvUser !== null) && (
                <Menu.Item component={NavLink} onClick={unassumeUser} className="Menu-item" to="/assumeuser">
                  Unassume user
                </Menu.Item>
              )}
            </Menu.Dropdown>
          </Menu>
        </div>
      </div>
    </>
  )
}

const MenuItem = ({ title, to }: { title: string, to: string }) => {
  return (
    <NavLink className={({ isActive }) => isActive ? "nav-link bv-active" : "nav-link"} to={to}>{title}</NavLink>
  )
}