import {useQuery} from '@tanstack/react-query';
import {GetReservationsAndRestrictions} from "../api";
import {dotNetToJSDate} from "../helpers";
import {useDays} from "./useDaysInMonth";

const translator = new Intl.DateTimeFormat("sv-SE")

export const useReservationsAndRestrictions = ({selectedMonth, selectedObjectId}: {
    selectedMonth: string,
    selectedObjectId?: string
}) => {
    const days = useDays(selectedMonth)

    return useQuery(
        ['reservationsAndRestrictions', selectedObjectId, days],
        async () => {
            if (!selectedObjectId) {
                throw new Error("Selected object ID is undefined");
            }

            const result = await GetReservationsAndRestrictions(
                translator.format(days.lastMonth[days.lastMonth.length - 15]),
                translator.format(days.nextMonth[15]),
                selectedObjectId,
                false,
                true
            );

            if (result === undefined || "error" in result) {
                return {
                    reservations: [],
                    restrictions: [],
                    allUnavailable: true,
                };
            }

            if ("reservations" in result) {
                result.reservations?.forEach(x => {
                    x.arrival = dotNetToJSDate(x.arrival!);
                    x.departure = dotNetToJSDate(x.departure!);
                });

                const filteredReservations = result.reservations?.filter(
                    x => x.roomTypeId === selectedObjectId || x.roomUnitId === selectedObjectId
                );

                return {
                    reservations: filteredReservations,
                    restrictions: result.restrictions,
                    allUnavailable: false,
                };
            }

            return {
                reservations: [],
                restrictions: [],
                allUnavailable: true,
            };
        },
        {
            enabled: !!selectedObjectId && days.thisMonth.length > 0,
            keepPreviousData: true,
            staleTime: Infinity,
            onError: (error) => {
                console.error(error);
            },
        }
    );
};
