import React, { FC, useContext, useEffect, useState } from "react"

// Components
import { Calendar } from "../components/icons"
import { RatesCalendar } from "../components/rates_calendar"
import RateChangeModal from "../components/rate_change_modal"
import Snackbar from "@material-ui/core/Snackbar"
import { TranslationContext } from "../translationcontext"

// Types
import { RatesProps } from "./interfaces"
import { RateModel } from "../generated_api"


export const Rates: FC<RatesProps> = ({ selectedObject, onSelectedObjectChanged, ownersObjects }) => {
  const translation = useContext(TranslationContext)

  const [rateChangeModalState, setRateChangeModalState] = useState<{
    ratePlanId: string | undefined,
    show: boolean,
    dateClicked: Date | undefined,
    roomId?: string,
    prices: RateModel[]
  }>({ show: false, dateClicked: undefined, ratePlanId: undefined, prices: [] })

  const [snackbarState, setSnackbarState] = useState({ show: false, text: "", color: "seagreen" })
  const [selectedMonth, setSelectedMonth] = useState(`${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, "0")}`)
  const [reloadData, setReloadData] = useState(false)
  const [isCalendarLoading, setIsCalendarLoading] = useState(false)
  const [ratePlans, setRatePlans] = useState<Array<{
    ratePlanName: string,
    ratePlanId: string,
    currencyCode: string,
    priceStructure: string
  }>>([])
  const [selectedRatePlanId, setSelectedRatePlanId] = useState<string | undefined>()

  useEffect(() => {
    if (!selectedRatePlanId && ratePlans.length > 0) {
      setSelectedRatePlanId(ratePlans[0].ratePlanId)
    }
  }, [ratePlans, selectedRatePlanId])


  const rateChangeModalClosing = (saveSuccess?: boolean) => {
    setRateChangeModalState(s => ({ ...s, show: false }))
    if (saveSuccess) {
      setSnackbarState({ show: true, text: translation.common?.saved!, color: "seagreen" })
      setReloadData((x) => !x)
    }
  }


  const showSnackbar = React.useCallback((message: string, color?: string) => {
    setSnackbarState({ show: true, color: color ?? "#44923d", text: message })
  }, [])

  const OnRatePlansFetched = React.useCallback((ratePlans: Array<{
    ratePlanName: string,
    ratePlanId: string,
    currencyCode: string,
    priceStructure: string
  }>) => setRatePlans(ratePlans), [])

  return (
    <>
      <Snackbar className="custom-snack" style={{ ...{ "--bgColor": snackbarState.color } as React.CSSProperties }}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }} open={snackbarState.show}
                autoHideDuration={4000}
                onClose={() => setSnackbarState(x => ({ ...x, show: false }))} message={snackbarState.text}
                key="bottomcenter" />
      <RateChangeModal prices={rateChangeModalState.prices} RatePlans={ratePlans} ratePlanId={selectedRatePlanId}
                       onFail={() => showSnackbar(translation.common?.somethingWentWrong!)}
                       guestRoomId={selectedObject ?? ""} onClose={rateChangeModalClosing}
                       show={rateChangeModalState.show} selectedDate={rateChangeModalState.dateClicked} />


      <div className="bv-main">
        <div className="bv-inner">
          <div className="bv-title-main">
            <div className="bv-icon">
              <Calendar />
            </div>
            <h1>{translation.rates?.rates}</h1>
            {ownersObjects.length > 0 && <div className="bv-actions">
              <div>
                <p style={{ marginBottom: "1px" }}>{translation.rates?.rateplans}:</p>
                <select onChange={(e: any) => setSelectedRatePlanId(e.target.value)} disabled={isCalendarLoading}
                        className="custom-select custom-select-lg" value={selectedRatePlanId}>
                  {ratePlans.map(ratePlan => {
                    return (
                      <option id={ratePlan.ratePlanName} value={ratePlan.ratePlanId}
                              key={ratePlan.ratePlanName}>{ratePlan.ratePlanName}</option>
                    )
                  })}
                </select>
              </div>
              <div>
                <p style={{ marginBottom: "1px" }}>{translation.common?.myObjects}:</p>
                <select disabled={isCalendarLoading} className="custom-select custom-select-lg"
                        value={selectedObject}
                        onChange={(newValue: any) => onSelectedObjectChanged(newValue.target.value)}
                >
                  {ownersObjects.map(o => {
                    return <option value={o.id} key={o.id}>{o.name}</option>
                  })}
                </select>
              </div>
              <div>
                <p style={{ marginBottom: "1px" }}>{translation.financeReport?.month}:</p>
                <input disabled={isCalendarLoading} className="custom-select-lg month-picker"
                       onChange={(x: any) => setSelectedMonth(x.target.value)} style={{
                  textTransform: "capitalize", background: "transparent", height: "40px", borderRadius: "3px",
                  border: "1px solid #cfcecc", paddingRight: "0.2rem", width: "100%"
                }} type="month" id="start" name="start" min="2020-01" value={selectedMonth} />
              </div>
            </div>}
          </div>
          {selectedObject !== undefined &&
            <RatesCalendar reloadTrigger={reloadData} SelectedRatePlanId={selectedRatePlanId}
                           OnRatePlansFetched={OnRatePlansFetched}
                           OnDayClicked={(day: Date, ratePlanId: string, roomId: string, prices: RateModel[]) => {
                             setRateChangeModalState(x => ({
                               ...x,
                               show: true,
                               ratePlanId: ratePlanId,
                               roomId: roomId,
                               dateClicked: day,
                               prices: prices
                             }))
                           }} DataFetchFailed={() => {
            }} IsLoading={isCalendarLoading} SetIsLoading={setIsCalendarLoading} selectedObjectId={selectedObject}
                           onMonthChanged={(newValue: string) => new Date(newValue).getFullYear() >= 2020 && setSelectedMonth(newValue)}
                           selectedMonth={selectedMonth} />
          }
        </div>
      </div>
    </>
  )
}