import {useQuery} from "@tanstack/react-query";
import {UC} from "../usercontext";
import { Client } from "../generated_api";

export const useIsFeatureEnabled = (user: UC, featureFlag:string) => {
    const apiClient = new Client({getAuthorization: () => `Bearer ${user.user?.token}`}, "https://ownersapi.bookvisit.com")

    return useQuery(
        ["feature_flag", user?.user?.id, featureFlag],
        () => apiClient.featureToggle(featureFlag), 
        {
            enabled: Boolean(user?.user),
            select: (data) => Boolean(data.enabled)
        }
    )
}