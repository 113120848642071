import React, {FC, useCallback, useContext, useEffect, useState} from "react"

// Hooks
import {useDateTimeFormat} from "../hooks/useDateTimeFormat";

// Components
import {UserContext} from "../usercontext"
import {TranslationContext} from "../translationcontext"
import {ArrowRightIOS, StartIcon, Users, WeatherNightClear} from "../components/icons"
import {BookingDetailsModal} from "../components/bookingdetails_modal"
import {Loader} from "@mantine/core"

// API
import {GetAllFutureReservations, GetOwnersObjects} from "../api"

// Lib
import {dotNetToJSDate, getDaysBetweenDates} from "../helpers"

// Types
import {HomeProps, HomeState} from "./interfaces"
import {OwnerPortalReservation} from "../generated_api"

let imageUrl = process.env.NODE_ENV === "development" ? "http://images.local/img/" : "https://images.bookvisit.com/img/"

export const Home: FC<HomeProps> = ({ProfileImageUrl}) => {
    let userContext = useContext(UserContext)
    let translations = useContext(TranslationContext)
    let [state, setState] = useState<HomeState>({
        isLoading: true,
        showBookingDetailsModal: false,
        bookingsSinceLastLogin: 0,
        nextArrivalStayLength: 0,
        usersObjects: []
    })

    const {languageCulture} = useDateTimeFormat()

    const FetchData = useCallback(async () => {

        let [{ownerPortalReservations: reservations}, objects] = await Promise.all([
            GetAllFutureReservations(undefined, false),
            GetOwnersObjects()
        ])

        let allOwnersGuestRoomIds = objects.map(x => x.id)

        const sortedReservations =
            (!reservations || reservations.length === 0) ? []
                : reservations.sort((a, b) => new Date(a.arrival!).getTime() - new Date(b.arrival!).getTime())

        let next =
            sortedReservations.length === 0 ? undefined
                : sortedReservations.find(x => allOwnersGuestRoomIds.includes(x.roomTypeId) || allOwnersGuestRoomIds.includes(x.roomUnitId))

        if (next) {
            next.arrival = dotNetToJSDate(next?.arrival!)
            next.departure = dotNetToJSDate(next?.departure!)
        }

        let numberOfBookingsSinceLastAuth = 0
        if (userContext.user) {
            let bookingsSinceLastAuth = reservations?.filter((r: OwnerPortalReservation) => {
                return new Date(r.bookedDate!) > new Date(userContext.user!.timeOfAuth!)
            })
            numberOfBookingsSinceLastAuth = bookingsSinceLastAuth?.length ?? 0
        }

        let nextArrivalStayLength = 0

        setState(s => ({
            ...s,
            nextArrival: next,
            bookingsSinceLastLogin: numberOfBookingsSinceLastAuth,
            nextArrivalStayLength: next ? getDaysBetweenDates(next.arrival!, next.departure!).length : nextArrivalStayLength,
            usersObjects: objects,
            isLoading: false
        }))
    }, [userContext.user])

    useEffect(() => {
        FetchData()
    }, [FetchData])

    const backgroundImageStyle = {
        backgroundImage: `url(${ProfileImageUrl})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center"
    }

    return (
        <div className="bv-main">
            <BookingDetailsModal onReservationCancelled={() => {
            }} onClose={() => setState((prev) => ({...prev, showBookingDetailsModal: false}))}
                                 showDrawer={state.showBookingDetailsModal} imageUrl={imageUrl}
                                 booking={state.nextArrival}/>
            <div className="bv-inner">
                <div className="bv-title-main">
                    <div className="bv-icon" style={ProfileImageUrl ? backgroundImageStyle : {}}>
                        {!ProfileImageUrl && <StartIcon/>}
                    </div>
                    <h1>{translations.home?.greeting}, {userContext?.user?.firstName}</h1>
                </div>

                <div className="vg-transition-fade-in-down-children">
                    <div className="vg-grid-row vg-grid-2 vg-gutter-lg vg-justify">
                        <div className="vg-col">
                            <div className="vg-dashboard start-dashboard-dark start-dashboard-arrival">
                                <div className="vg-dashboard-title">
                                    <h3>{translations.common?.nextArrival}</h3>
                                    <div className="vg-actions">
                                        <a href="#bookings" className="vg-text-icon small">
                                            <strong>{translations.common?.viewAll}</strong>
                                            <span className="vg-icon"><ArrowRightIOS/></span>
                                        </a>
                                    </div>
                                </div>
                                {state.isLoading && <Loader style={{margin: "30px"}}/>}
                                {(!state.isLoading && state.nextArrival === undefined) &&
                                    <h2 style={{padding: "30px"}}>{translations.common?.none}</h2>}
                                {(!state.isLoading && state.nextArrival) && <a href="#top"
                                                                               onClick={() => state.nextArrival && setState(x => ({
                                                                                   ...x,
                                                                                   showBookingDetailsModal: true
                                                                               }))} className="vg-dashboard-content"
                                                                               data-toggle="modal"
                                                                               data-target="#moda_booking_owner">
                                    <div className="summary">
                                        <div>
                                            <div className="icon">
                                                <WeatherNightClear/>
                                            </div>
                                            <h3
                                                style={{textTransform: "capitalize"}}>{state.nextArrivalStayLength} {state.nextArrivalStayLength > 1 ? translations.common?.nights : translations.common?.night}</h3>
                                        </div>
                                        <div>
                                            <div className="icon">
                                                <Users/>
                                            </div>
                                            <h3
                                                style={{textTransform: "capitalize"}}>{state.nextArrival && state.nextArrival.guests?.length} {state.nextArrival !== undefined && (state.nextArrival.guests?.length ?? 0) > 1 ? translations.common?.guests : translations.common?.guest}</h3>
                                        </div>
                                    </div>

                                    <table>
                                        <tbody>
                                        <tr>
                                            <td><small>{translations.common?.arrival}</small></td>
                                            <td
                                                style={{textTransform: "capitalize"}}>{state.nextArrival && state.nextArrival.arrival?.toLocaleDateString(languageCulture, {
                                                weekday: "long",
                                                month: "long",
                                                day: "numeric"
                                            })}</td>
                                        </tr>
                                        <tr>
                                            <td><small>{translations.common?.departure}</small></td>
                                            <td
                                                style={{textTransform: "capitalize"}}>{state.nextArrival && state.nextArrival.departure?.toLocaleDateString(languageCulture, {
                                                weekday: "long",
                                                month: "long",
                                                day: "numeric"
                                            })}</td>
                                        </tr>
                                        <tr>
                                            <td><small>{translations.common?.booker}</small></td>
                                            <td>{state.nextArrival && state.nextArrival.customerName}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </a>}
                            </div>
                        </div>
                        <div className="vg-col">
                            <div className="vg-dashboard start-dashboard-dark start-dashboard-status">
                                <div className="vg-dashboard-title"><h3>{translations.common?.myStatus}</h3></div>
                                <div className="vg-dashboard-content">
                                    <a href="#bookings">
                                        <span className="display-3">{state.bookingsSinceLastLogin}</span>
                                        <b>{translations.common?.newBookingsSinceLastLogin}</b>
                                        <span className="vg-icon">
                                        <ArrowRightIOS/>
                                        </span>
                                    </a>
                                    <a href="#myobjects">
                                        <span className="display-3">{state.usersObjects.length}</span>
                                        <b>{translations.common?.sellableObjectRegistered}</b>
                                        <span className="vg-icon">
                                        <ArrowRightIOS/>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="vg-grid-row vg-gutter-lg vg-justify" style={{zIndex: 20}}>
					<div className="vg-col">					
						<div className="vg-dashboard start-dashboard-help-title vg-bg-light-100">
							<div className="vg-dashboard-content">
								<h2>Help center</h2>
								<p className="text-muted">Find answers to common questions and view our easy to understand tutorials in the help center.</p>
							</div>
							<img src="../dashboard-laptop.png"/>
						</div>
						<div className="vg-dashboard start-dashboard-help-main">
							<div className="vg-dashboard-content">
								<div className="index">
									<div className="vg-simple-grid vg-fullwidth">
										<div className="vg-border-light">
											<b>Bookings</b>
											<ul>
												<li><a href="#">Lorem ipsum dolor sit amet consectetur</a></li>
												<li><a href="#">Adipiscing elit, sed do eiusmod tempor</a></li>
												<li><a href="#">Incididunt ut labore et dolore magna</a></li>
											</ul>
										</div>
										<div className="vg-border-light">
											<b>Availability</b>
											<ul>
												<li><a href="#">Lorem ipsum dolor sit amet consectetur</a></li>
												<li><a href="#">Adipiscing elit, sed do eiusmod tempor</a></li>
												<li><a href="#">Incididunt ut labore et dolore magna</a></li>
												<li><a href="#">Lorem ipsum dolor sit amet consectetur</a></li>
											</ul>
										</div>
										<div className="vg-border-light">
											<b>Financial</b>
											<ul>
												<li><a href="#">Lorem ipsum dolor sit amet consectetur</a></li>
												<li><a href="#">Adipiscing elit, sed do eiusmod tempor</a></li>
												<li><a href="#">Incididunt ut labore et dolore magna</a></li>
											</ul>
										</div>
										<div className="vg-border-light">
											<b>My objects</b>
											<ul>
												<li><a href="#">Lorem ipsum dolor sit amet consectetur</a></li>
												<li><a href="#">Adipiscing elit, sed do eiusmod tempor</a></li>
											</ul>
										</div>
									</div>
								</div>

								<div className="text-right">
									<a href="javascript:void(0);" className="vg-text-icon small">
										<strong>Go to Help center</strong>
										<span className="vg-icon">
                                            <ArrowRightIOS/>
                                            </span>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div> */}
                </div>


            </div>
        </div>
    )
}