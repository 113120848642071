import React, { useContext, useState } from "react"

// Hooks
import { useMyObjects } from "../hooks/useMyObjects"

// Components
import { MyObjectModal } from "../components/myobjectmodal"
import { Pen } from "../components/icons"
import { TranslationContext } from "../translationcontext"
import { Loader } from "@mantine/core"
import { GuestRoomInfo } from "../generated_api"

const imageUrl = process.env.NODE_ENV === "development" ? "http://images.local/img/" : "https://images.bookvisit.com/img/"

export const MyObjects = () => {
  const translations = useContext(TranslationContext)

  const [showdrawer, setshowdrawer] = useState(false)
  const [selectedObject, setSelectedObject] = useState<GuestRoomInfo | undefined>(undefined)
  const { data: state, isLoading } = useMyObjects()

  function openRoomDetailModal(roomTypeId: string) {
    let selectedObject = state?.find(x => x.id === roomTypeId)
    if (selectedObject !== undefined) {
      setshowdrawer(true)
      setSelectedObject(selectedObject)
    }
  }

  return (
    <>
      <MyObjectModal selectedObject={selectedObject} showDrawer={showdrawer}
                     setShowDrawer={setshowdrawer}></MyObjectModal>
      <div className="bv-main">
        <div className="bv-inner">
          <div className="bv-title-main">
            <div className="bv-icon">
              <Pen />
            </div>
            <h1>{translations.myObjects?.infoAboutMyObjects}</h1>
          </div>
          <div>
            {isLoading && <div style={{ textAlign: "center" }}><Loader /></div>}

            {!isLoading && (
              <div className="vg-grid-row vg-grid-3 vg-gutter-lg vg-justify">
                {state?.map(guestroomInfo => {
                    let photoStyle = guestroomInfo.mainImageId != null ? {
                      backgroundImage: `url(${imageUrl}${guestroomInfo.mainImageId}.jpg?maxwidth=600&maxheight=600&scale=downscaleonly&anchor=MiddleCenter&sharpen=0.6)`
                    } : {}
                    return (
                      <div key={guestroomInfo.id}
                           className="vg-col"
                           onClick={() => openRoomDetailModal(guestroomInfo.id!)}
                           style={{ cursor: "pointer" }}>
                        <div className="vg-panel bv-card-object">
                          <div className="vg-photo" style={photoStyle} />
                          <div className="vg-panel-content">
                            <div>
                              <h3>{guestroomInfo.name}</h3>
                              <div className="small vg-text-dots text-muted">
                                <span>{translations.myObjects?.beds?.replace("{0}", guestroomInfo.nrOfBeds!.toString())}</span>
                                <span>{guestroomInfo.roomSize}</span>
                                <span>{translations.myObjects?.maxOccupancy?.replace("{0}", guestroomInfo.maxOccupancy!.toString())}</span>
                              </div>
                            </div>
                            <p>{guestroomInfo.searchResultText}</p>
                          </div>
                        </div>
                      </div>
                    )
                  }
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}