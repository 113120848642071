import React, {FC, useContext, useEffect, useMemo, useState} from "react"

// Hooks
import {useDateTimeFormat} from "../hooks/useDateTimeFormat";

// Components
import {ArrowLeft, ArrowRight} from "./icons"
import {Loader} from "@mantine/core"
import {TranslationContext} from "../translationcontext"

// Lib
import {getDaysInMonthUTC, getWeekNumber, monthNumberToString, useIsMounted} from "../helpers"

// API
import {GetAllRateplans} from "../api"

// Types
import {RatesCalendarProps} from "./interfaces"
import {RateModel} from "../generated_api"

export const RatesCalendar: FC<RatesCalendarProps> = (
    {
        IsLoading, OnRatePlansFetched, selectedObjectId,
        SelectedRatePlanId, selectedMonth, onMonthChanged,
        reloadTrigger, OnDayClicked
    }) => {
    const rawTranslations = useContext(TranslationContext)
    const translations = useMemo(() => rawTranslations, [rawTranslations])

    const {dateTimeFormatter} = useDateTimeFormat()

    const [days, setDays] = useState<{ lastMonth: Date[], thisMonth: Date[], nextMonth: Date[] }>({
        lastMonth: [],
        thisMonth: [],
        nextMonth: []
    })
    const [prices, setPrices] = useState<RateModel[]>()
    const [fetchingPrices, setFetchingPrices] = useState(false)

    const isMounted = useIsMounted()

    useEffect(() => {
        if (!isMounted.current) {
            return
        }

        let month = new Date(selectedMonth)
        let [days, daysLastMonth, daysNextMonth] = getDaysInMonthUTC(month.getMonth(), month.getFullYear())

        if (selectedObjectId) {
            setFetchingPrices(true)
            GetAllRateplans(selectedObjectId, daysLastMonth[daysLastMonth.length - 7].toLocaleDateString("sv-SE"), daysNextMonth[7].toLocaleDateString("sv-SE")).then((data: RateModel[]) => {
                if (!data) {
                    return
                }

                OnRatePlansFetched(data.map(x => ({
                    ratePlanName: x.ratePlanName!,
                    ratePlanId: x.ratePlanId!,
                    currencyCode: x.currencyCode!,
                    priceStructure: x.priceStructure!
                })))

                setPrices(data?.map((r: any) => ({
                    priceStructure: r.priceStructure,
                    priceValidDaysMax: r.priceValidDaysMax,
                    priceValidDaysMin: r.priceValidDaysMin,
                    currencyCode: r.currencyCode,
                    ratePlanName: r.ratePlanName,
                    ratePlanId: r.ratePlanId,
                    ratePlanInfo: r.ratePlanInfo,
                    periods: r.periods,
                    fakePeriodRate: !r.fakePeriodRate ? undefined : {
                        arrivalDay: r.fakePeriodRate.arrivalDay,
                        departureDay: r.fakePeriodRate.departureDay,
                        los: r.fakePeriodRate.los,
                        stayLength: r.fakePeriodRate.stayLength
                    }
                })))
                setFetchingPrices(false)
            })
        }


        if (isMounted.current) {
            setDays({
                lastMonth: daysLastMonth,
                thisMonth: days,
                nextMonth: daysNextMonth
            })
        }
    }, [selectedMonth, selectedObjectId, isMounted, reloadTrigger, OnRatePlansFetched])

    const WeekdaysRow = (weekNumber: number, days: Date[]) => {
        let selectedRatePlan = prices?.find(x => x.ratePlanId === SelectedRatePlanId)

        const addFakePeriodPriceString = () => {
            if (selectedRatePlan?.fakePeriodRate === undefined) {
                return
            }
            return `(${selectedRatePlan.fakePeriodRate.stayLength}n)`
        }

        return (
            <div key={weekNumber} className="bv-row bv--week-days">
                <div className="bv-week">
                    <div>
                        {weekNumber}
                    </div>
                </div>
                {days.map((day, index) => {
                    let dayOfWeek = day.getDay()
                    let priceThisDay: undefined | number = undefined
                    let priceValidDaysMin = 0
                    let priceValidDaysMax = 0
                    if (selectedRatePlan) {
                        selectedRatePlan.ratePlanInfo?.forEach(price => {
                            let thisDay = price.ratePlanItems?.find(d => {
                                let d1 = dateTimeFormatter.format(new Date(d.date!))
                                let d2 = dateTimeFormatter.format(day)
                                return d1 === d2
                            })

                            if (thisDay?.amount && (priceThisDay === undefined || thisDay!.amount < priceThisDay)) {
                                priceThisDay = thisDay!.amount

                                if (selectedRatePlan?.fakePeriodRate) {
                                    priceThisDay = thisDay!.amount * selectedRatePlan.fakePeriodRate.stayLength!
                                }
                            }
                        })

                        if (priceThisDay && selectedRatePlan.priceValidDaysMin && selectedRatePlan.priceValidDaysMin > 0) {
                            priceValidDaysMin = selectedRatePlan.priceValidDaysMin
                        }
                        if (selectedRatePlan.priceValidDaysMax && selectedRatePlan.priceValidDaysMax > 0) {
                            priceValidDaysMax = selectedRatePlan.priceValidDaysMax
                        }
                    }

                    const buildPeriodPriceNightString = () => {
                        if (priceValidDaysMin === 0) {
                            return
                        }

                        if (priceValidDaysMin !== 0 && priceValidDaysMax > priceValidDaysMin) {
                            return `(${priceValidDaysMin}-${priceValidDaysMax}n)`
                        }

                        if (priceValidDaysMin !== 0) {
                            return `(${priceValidDaysMin}n)`
                        }
                    }
                    let isPastDay = false
                    let cName = "bv-day"
                    if (dateTimeFormatter.format(day) < dateTimeFormatter.format(new Date())) {
                        cName = "bv-day bv-day-past"
                        isPastDay = true
                    }

                    let dayShouldBeEnabled = selectedRatePlan?.fakePeriodRate !== undefined

                    selectedRatePlan?.periods?.filter(p => p.hasDefaultPrices)?.forEach(period => {
                        if (period.isSeasonalPeriod && period.weekdays?.includes(day.getDay())) {
                            let startDate = new Date(new Date(period.startDate!).setHours(0))
                            let endDate = new Date()
                            day.setHours(0)
                            if (!period.endDate) {
                                endDate = new Date(startDate)
                                endDate.setFullYear(endDate.getFullYear() + 1)
                            } else {
                                endDate = new Date(new Date(period.endDate!).setHours(0))
                            }

                            if (day >= startDate && day <= endDate) {
                                dayShouldBeEnabled = true
                            }
                        }

                        if (!period.isSeasonalPeriod && period.weekdays?.includes(day.getDay())) {
                            dayShouldBeEnabled = true
                        }
                    })

                    if ((!dayShouldBeEnabled && !isPastDay) || (selectedRatePlan?.fakePeriodRate !== undefined && dayOfWeek !== selectedRatePlan?.fakePeriodRate?.arrivalDay)) {
                        cName = "bv-day bv-ratescal-notavail"
                    }

                    return (
                        <React.Fragment key={`${dateTimeFormatter.format(day)}_${index}`}>
                            <div style={{userSelect: "none", display: "grid", placeItems: "center"}} className={cName}>
                              
                                <span className="bv-date">
                                    {day.getDate()}
                                </span>
                                {dayShouldBeEnabled && !(selectedRatePlan?.fakePeriodRate !== undefined && dayOfWeek !== selectedRatePlan?.fakePeriodRate?.arrivalDay) &&
                                    <button className="edit-rate-button" disabled={isPastDay} style={{
                                        background: "transparent",
                                        border: 0,
                                        color: isPastDay ? "#00000070" : "black",
                                        borderBottom: isPastDay ? "0" : "1px solid black",
                                        margin: 0,
                                        padding: 0
                                    }} onClick={() => {
                                        OnDayClicked(day, SelectedRatePlanId!, selectedObjectId!, prices!)
                                    }}>
                                        {priceThisDay && <p style={{
                                            margin: 0,
                                            fontSize: "0.8rem"
                                        }}>{priceThisDay} {selectedRatePlan?.currencyCode} {addFakePeriodPriceString()} {buildPeriodPriceNightString()}</p>}
                                        {(!priceThisDay) && !fetchingPrices &&
                                            <p style={{
                                                margin: 0,
                                                fontSize: "0.8rem"
                                            }}>{translations.common?.noPriceInfo}</p>}
                                    </button>
                                }
                            </div>
                        </React.Fragment>
                    )
                })}
            </div>
        )
    }


    const renderWeekRows = () => {
        if (days.thisMonth.length === 0) {
            return
        }

        let daysArr = [...days.thisMonth]
        let daysLastMonthArr = [...days.lastMonth]
        let daysNextMonthArr = [...days.nextMonth]
        let res = []

        while (daysArr[0].getDay() !== 1) {
            let lastDayLastMonth = daysLastMonthArr.pop()
            daysArr.unshift(lastDayLastMonth!)
        }

        let lastWeekThisMonth = getWeekNumber(days.thisMonth.slice(-1)[0])

        let nrOfDaysLastWeekInMonth = daysArr.filter(x => getWeekNumber(x) === lastWeekThisMonth)

        let missingDays = 7 - nrOfDaysLastWeekInMonth.length

        for (let i = 0; i < missingDays; i++) {
            daysArr.push(daysNextMonthArr.shift()!)
        }

        while (daysArr.length > 0) {
            let weekNr = getWeekNumber(daysArr[0])
            res.push(WeekdaysRow(weekNr, daysArr.splice(0, 7)))
        }
        return res
    }

    const DayNameHeader = () => {
        return (

            <div className="bv-row bv--week-name">
                <div className="bv-week">
                    <span className="bv-long">{translations.availability?.week}</span>
                    <span className="bv-short">{translations.availability?.week}</span>
                </div>
                <div className="bv-day">
                    <span className="bv-long">{translations.availability?.monday}</span>
                    <span className="bv-short">{translations.availability?.mondayThreeLetters}</span>
                </div>
                <div className="bv-day">
                    <span className="bv-long">{translations.availability?.tuesday}</span>
                    <span className="bv-short">{translations.availability?.tuesdayThreeLetters}</span>
                </div>
                <div className="bv-day">
                    <span className="bv-long">{translations.availability?.wednesday}</span>
                    <span className="bv-short">{translations.availability?.wednesdayThreeLetters}</span>
                </div>
                <div className="bv-day">
                    <span className="bv-long">{translations.availability?.thursday}</span>
                    <span className="bv-short">{translations.availability?.thursdayThreeLetters}</span>
                </div>
                <div className="bv-day">
                    <span className="bv-long">{translations.availability?.friday}</span>
                    <span className="bv-short">{translations.availability?.fridayThreeLetters}</span>
                </div>
                <div className="bv-day">
                    <span className="bv-long">{translations.availability?.saturday}</span>
                    <span className="bv-short">{translations.availability?.saturdayThreeLetters}</span>
                </div>
                <div className="bv-day">
                    <span className="bv-long">{translations.availability?.sunday}</span>
                    <span className="bv-short">{translations.availability?.sundayThreeLetters}</span>
                </div>
            </div>
        )
    }

    const GoBackOneMonth = () => {
        let currentDate = new Date(selectedMonth)

        let year = currentDate.getFullYear()
        let monthNr = currentDate.getMonth()
        if (monthNr < 1) {
            monthNr = 12
            year -= 1
        }

        onMonthChanged(`${year}-${monthNr.toString().padStart(2, "0")}`)
    }

    const GoForwardOneMonth = () => {
        let currentDate = new Date(selectedMonth)
        let year = currentDate.getFullYear()
        let monthNr = currentDate.getMonth() + 2
        if (monthNr > 12) {
            monthNr = 1
            year += 1
        }

        onMonthChanged(`${year}-${monthNr.toString().padStart(2, "0")}`)
    }

    return (
        <div>
            <div className="vg-panel"
                 style={{opacity: fetchingPrices ? "0.5" : "1", userSelect: "none"}}
            >
                <div className="vg-panel-header">


                    <div className="vg-panel-title">
                        <h3>{monthNumberToString(new Date(selectedMonth).getMonth(), translations)} {new Date(selectedMonth).getFullYear()}</h3>
                    </div>
                    <div className="vg-actions">
                        {fetchingPrices &&
                            <Loader style={{verticalAlign: "middle", marginRight: "10px"}}/>}

                        <button disabled={IsLoading} onClick={GoBackOneMonth} className="btn btn-secondary"
                                type="button">
                            <span className="vg-icon vg-icon-only">
                                <ArrowLeft/>
                            </span>
                        </button>

                        <button disabled={IsLoading} onClick={GoForwardOneMonth} className="btn btn-secondary"
                                type="button">
                            <span className="vg-icon vg-icon-only">
                                <ArrowRight/>
                            </span>
                        </button>
                    </div>
                </div>

                <div className="bv-avail-calendar">
                    <div className="bv-inner">
                        <DayNameHeader/>
                        {renderWeekRows()}
                    </div>
                </div>
            </div>

            <div className="bv-legends">
                <div className="bv-legend">
                    <div className="bv-calmarker-past-date">
                        <div></div>
                    </div>
                    <div>{translations.availability?.pastDate}</div>
                </div>
                <div className="bv-legend">
                    <div className="bv-ratescal-notavail">
                        <div>
                        </div>
                    </div>
                    <div>{translations.rates?.rateUnavailable}</div>
                </div>
            </div>
        </div>
    )
}