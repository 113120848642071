import { useQuery } from "@tanstack/react-query"
import * as API from "../api"

export const useFinancialYears = () => {

  return useQuery(["financialYears"], async () => {
    const result = await API.GetFinanceYearsCalendarObjects()

    // if (!isMounted.current) {
    //   return
    // }

    // if (result.length > 0 && result.indexOf(new Date().getFullYear()) < 0) {
    //   const lastItem = result.pop()
    //   setSelectedYear(lastItem)
    // }

    return result
  }, {
    staleTime: Infinity,
    refetchOnWindowFocus: false
  })
}
