export const getLanguageCultureById = (languageId: number) => {
    switch (languageId) {
        case 1:
            return "sv-SE" // Swedish
        case 2:
            return "en-US" // English
        case 3:
            return "de-DE" // German
        case 4:
            return "da-DK" // Danish
        case 5:
            return "no-NO" // Norwegian
        case 6:
            return "fr-FR" // French
        case 8:
            return "it-IT" // Italian
        case 9:
            return "es-ES" // Spanish
        case 10:
            return "fi-FI" // Finnish
        case 11:
            return "ja-JP" // Japanese
        case 12:
            return "ru-RU" // Russian
        case 15:
            return "lt-LT" // Lithuanian
        case 16:
            return "lv-LV" // Latvian
        case 17:
            return "et-EE" // Estonian
        case 18:
            return "pt-PT" // Portuguese
        case 19:
            return "nl-NL" // Dutch
        case 24:
            return "ar-SA" // Arabic
        case 27:
            return "ko-KR" // Korean
        case 30:
            return "zh-CN" // Chinese
        case 40:
            return "ka-GE" // Georgian
        default:
            return "en-US" // Default to English for unknown language id
    }
}