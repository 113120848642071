import React, { FC, useContext, useEffect, useState } from "react"

// Hooks
import { useFinancialYears } from "../hooks/useFinancialYears"
import { useFinanceData } from "../hooks/useFinanceData"
import {useDateTimeFormat} from "../hooks/useDateTimeFormat";

// Components
import { Loader } from "@mantine/core"
import { TranslationContext } from "../translationcontext"

// Lib
import { currencyFormatter } from "../helpers"

// Types
import { FinancialProps } from "./interfaces"

export const Financial: FC<FinancialProps> = ({ selectedObject, onSelectedObjectChanged, ownersObjects }) => {
  const [selectedYear, setSelectedYear] = useState(`${new Date().getFullYear()}`)
  const translation = useContext(TranslationContext)

  const { data: items } = useFinancialYears()
  const { data: state, isSuccess: isLoadedResult } = useFinanceData(selectedYear, selectedObject)
  const {languageCulture} = useDateTimeFormat()

  useEffect(() => {
    if (items && items?.length > 0 && items?.indexOf(String(new Date().getFullYear())) < 0) {
      setSelectedYear(items?.slice(-1)[0])
    }
  }, [items])

  return (
    <div className="bv-main">
      <div className="bv-inner">
        <div className="bv-title-main">
          <h1>{translation.financeReport?.financialReport}</h1>
          <div className="bv-actions">
            <div>
              <select
                className="custom-select custom-select-lg"
                value={selectedObject}
                onChange={(newValue: any) =>
                  onSelectedObjectChanged(newValue.target.value)
                }
              >
                {
                  ownersObjects.map((o) => {
                    return (
                      <option value={o.id} key={o.id}>
                        {o.name}
                      </option>
                    )
                  })
                }
              </select>
            </div>
            <div>
              <select
                className="custom-select custom-select-lg"
                value={selectedYear}
                onChange={(newValue: any) =>
                  setSelectedYear(newValue.target.value)
                }
              >
                {
                  items?.map((o: string) => {
                    return (
                      <option value={o} key={o}>
                        {o}
                      </option>
                    )
                  })
                }
              </select>
            </div>
          </div>
        </div>

        <div>
          <div className="vg-panel">
            <div className="vg-panel-header">
              <div className="vg-panel-title">
                <h3>
                  {translation.financeReport?.showingXMonths?.replace(
                    "{0}",
                    (state?.length ?? 0).toString()
                  )}
                </h3>
              </div>
              <div className="small text-muted">
                {translation.financeReport?.basedOnDepartureDate}
              </div>
            </div>
            <table className="table table-list table-striped table-hover">
              <thead className="thead-dark">
              <tr>
                <th>{translation.financeReport?.month}</th>
                <th className="text-right">
                  {translation.financeReport?.totalSalesIncVat}
                </th>
                <th className="text-right bv-hide-sm">
                  {translation.financeReport?.numberOfBookings}
                </th>

                <th className="text-right">
                  {translation.financeReport?.yourPayment}
                </th>
              </tr>
              </thead>
              <tbody>
              {
                isLoadedResult ?
                  state?.map((financeData, index) =>
                    <tr key={index} className="align-middle">
                      <td>
                        <b> {financeData.monthAndYear}</b>
                      </td>
                      <td className="text-right">
                        {currencyFormatter(financeData.currency!, financeData.totalSalesAmountIncVAT!, languageCulture)}
                        <div className="small text-muted bv-hide-lg">
                          {financeData.bookingsNumber} {translation.financeReport?.bookings}
                        </div>
                      </td>
                      <td className="text-right bv-hide-sm">{financeData.bookingsNumber}</td>
                      <td className="text-right">

                        <b>
                          {currencyFormatter(financeData.currency!, financeData.totalRevenueAmount!, languageCulture)}
                        </b>
                      </td>
                    </tr>
                  ) :
                  <tr>
                    <td className="align-middle">
                      <Loader />
                    </td>
                  </tr>
              }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}