import {useContext} from "react";
import {UserContext} from "../usercontext";
import {TranslationContext} from "../translationcontext";
import {getLanguageCultureById} from "../lib/getLagnuageCultureById";

export const useDateTimeFormat = () => {
    const userContext = useContext(UserContext)
    const translationsContext = useContext(TranslationContext)

    const languageId = userContext.user?.languageId ?? translationsContext?.langId
    const languageCulture = getLanguageCultureById(languageId!)
    const dateTimeFormatter = new Intl.DateTimeFormat(languageCulture)

    return {
        dateTimeFormatter,
        languageCulture
    }
}