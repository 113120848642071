import React, { FC, useContext } from "react"

// Components
import { Dialog, DialogContent } from "@material-ui/core"
import { Carousel } from "@mantine/carousel"
import { TranslationContext } from "../translationcontext"
import { useMediaQuery } from "react-responsive"

// Types
import { MyObjectModalProps } from "./interfaces"

const imageUrl = process.env.NODE_ENV === "development" ? "http://images.local/img/" : "https://images.bookvisit.com/img/"


export const MyObjectModal: FC<MyObjectModalProps> = ({ selectedObject, showDrawer, setShowDrawer }) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 500px)" })
  const translations = useContext(TranslationContext)

  return (
    <Dialog className="myobjectmodal" open={showDrawer} onClose={() => setShowDrawer(false)}
            fullScreen={isTabletOrMobile} maxWidth="md" scroll="body">
      <Carousel>
        {selectedObject?.allImageIds?.map(x =>
          <Carousel.Slide key={x}>
            <div style={{
              cursor: "grab",
              display: "grid",
              justifyItems: "center",
              backgroundPosition: "center",
              backgroundImage: `url('${imageUrl}${x}.jpg?maxwidth="200"&maxHeight=200&scale=downscaleonly&anchor=MiddleCenter&blur=50&contrast=0.2')`,
              height: isTabletOrMobile ? "260px" : "400px",
              backgroundSize: "cover"
            }}>
              <img
                src={`${imageUrl}${x}.jpg?maxwidth=900&maxHeight=400&scale=downscaleonly&anchor=MiddleCenter&contrast=0.2&sharpen=0.6`}
                height={isTabletOrMobile ? "260px" : "400px"} alt="" />
            </div>
          </Carousel.Slide>
        )}
      </Carousel>
      <DialogContent style={{ padding: "15px" }}>
        <button onClick={() => setShowDrawer(false)} style={{
          position: "absolute",
          right: "10px",
          top: "10px",
          zIndex: 10,
          background: "#00000087",
          border: 0,
          fontSize: "1.4rem",
          height: "40px",
          width: "40px",
          color: "white",
          borderRadius: "5px"
        }}>✕
        </button>
        <div style={{ paddingBottom: "25px" }}>
          <h1>{selectedObject?.name}</h1>
          <div className="IconSize22" style={{ paddingTop: "10px" }}>
                        <span style={{ display: "inline-flex" }}>
                            <span className="bv-icn-otherface-bed" style={{ paddingRight: "5px" }}></span>
                            <span
                              style={{ paddingRight: "25px" }}>{translations.myObjects?.beds?.replace("{0}", selectedObject?.nrOfBeds?.toString() ?? "")}</span>
                        </span>
            <span style={{ display: "inline-flex" }}>
                            <span className="bv-icn-otherface-size" style={{ paddingRight: "5px" }}></span>
                            <span style={{ paddingRight: "25px" }}>{selectedObject?.roomSize}</span>
                        </span>
            <span style={{ display: "inline-flex" }}>
                            <span className="bv-icn-otherface-persons" style={{ paddingRight: "5px" }}></span>
                            <span
                              style={{ paddingRight: "25px" }}>{translations.myObjects?.maxOccupancy?.replace("{0}", selectedObject?.maxOccupancy?.toString() ?? "")}</span>
                        </span>
          </div>
        </div>
        <div style={{
          display: "grid",
          gridTemplateColumns: isTabletOrMobile ? "repeat(auto-fill,minmax(300px,1fr))" : "repeat(auto-fill,minmax(400px,1fr))"
        }}>
          <div dangerouslySetInnerHTML={{ __html: selectedObject?.description ?? "" }} />

          <div
            className={isTabletOrMobile ? "objectAttributeTableMobile IconSize22" : "objectAttributeTable IconSize22"}
            style={{
              display: "grid",
              height: "max-content",
              gridTemplateColumns: isTabletOrMobile ? "repeat(1, minmax(200px, 1fr))" : "repeat(2, minmax(200px, 1fr))",
              justifySelf: isTabletOrMobile ? "" : "center",
              width: "100%"
            }}>
            {selectedObject?.attributes?.map(attribute =>
              <div key={attribute.id}
                   style={{ height: "40px", display: "grid", gridTemplateColumns: "auto 1fr", paddingLeft: "10px" }}>
                <div className={"bv-icn-roomfac-" + attribute.id}
                     style={{ alignSelf: "center", paddingRight: "5px" }} />
                <div style={{ alignSelf: "center" }}>{attribute.name}</div>
              </div>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
