import { useQuery } from "@tanstack/react-query"
import * as API from "../api"
import { GuestRoomInfo } from "../generated_api"

export const useMyObjects = () => {
  return useQuery(["myObjects"], async () => {
    const result: GuestRoomInfo[] = await API.GetRoomContent()
    if (result instanceof Error) {
      throw result
    } else if (result) {
      return result
    }
  }, {
    staleTime: Infinity
  })
}