import React, {FC, Fragment, useContext, useEffect, useState} from "react"

// Hooks
import {useDateTimeFormat} from "../hooks/useDateTimeFormat";

// Components
import {Dialog, DialogActions, DialogContent} from "@material-ui/core"
import {Button as MantineButton, Loader} from "@mantine/core"
import {TranslationContext} from "../translationcontext"
import {useMediaQuery} from "react-responsive"
import {UserContext} from "../usercontext"
import "react-datepicker/dist/react-datepicker.css"

// API
import {UpdateRatePlanAmount} from "../api"

// Types
import {DailyRatesAsPeriodRateWithExtraInfo, RateChangeModalProps} from "./interfaces"
import {GuestAmount, RateAgeGroup, RateModel, RatePlanAmount, RateUpdate} from "../generated_api"
import {getOneYearAheadString} from "../lib/getOneYearAheadString";

const RateChangeModal: FC<RateChangeModalProps> = (
    {
        ratePlanId, RatePlans, prices,
        onClose, show, selectedDate, guestRoomId
    }) => {
    const [loading, setLoading] = useState(false)
    const translations = useContext(TranslationContext)
    const [selectedFromDate, setSelectedFromDate] = useState<string | undefined>()
    const isTabletOrMobile = useMediaQuery({query: "(max-width: 500px)"})
    const [selectedDays, setSelectedDays] = useState<number[]>([])
    const [ratePlanAmounts, setRatePlanAmounts] = useState<RatePlanAmount[]>([])
    const [fakePeriodRateConfig, setFakePeriodRateConfig] = useState<DailyRatesAsPeriodRateWithExtraInfo | undefined>()
    const [selectedRatePlansForUpdate, setSelectedRatePlansForUpdate] = useState<string[]>([])

    const {languageCulture, dateTimeFormatter} = useDateTimeFormat()
    let {isBVUser} = useContext(UserContext)

    const SaveSpecialChanges = async () => {
        setLoading(true)

        let endDate = fakePeriodRateConfig?.departureDate!

        const rateUpdate: RateUpdate = {
            startDate: selectedDate!,
            endDate: new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() - 1), //We remove the last day since we dont want to change the price on the departure date.
            weekdays: [0, 1, 2, 3, 4, 5, 6],
            ratePlanAmounts: [
                {
                    ratePlanId: ratePlanId,
                    unitPriceAmount: fakePeriodRateConfig?.amount,
                    perGuestAmounts: []
                }
            ],
            roomTypeId: guestRoomId
        }

        let result = await UpdateRatePlanAmount(rateUpdate)
        setLoading(false)

        onClose(result)
    }

    const SaveChanges = async () => {
        let form: any = document.getElementById("editRateForm")
        if (!form.reportValidity()) {
            return
        }
        setLoading(true)

        let from = form.fromdate.value
        let to = form.todate.value

        let selectedRatePlanAmounts = ratePlanAmounts.filter(x => selectedRatePlansForUpdate.includes(x.ratePlanId!))

        const rateUpdate: RateUpdate = {
            startDate: from,
            endDate: to,
            weekdays: selectedDays,
            ratePlanAmounts: selectedRatePlanAmounts,
            roomTypeId: guestRoomId
        }

        let result = await UpdateRatePlanAmount(rateUpdate)
        setLoading(false)

        onClose(result)
    }

    useEffect(() => {
        if (show) {
            let selectedRatePlan = prices.find(x => x.ratePlanId === ratePlanId)
            if (selectedRatePlan?.fakePeriodRate !== undefined) {
                let toDate = new Date(selectedDate!.getFullYear(), selectedDate!.getMonth(), selectedDate!.getDate() + selectedRatePlan.fakePeriodRate.stayLength!)

                let defaultValue = 0
                for (let priceInfo of selectedRatePlan?.ratePlanInfo!) {
                    let thisDaysPrice = priceInfo.ratePlanItems?.find(x => dateTimeFormatter.format(new Date(x.date!)) === dateTimeFormatter.format(selectedDate))
                    if (thisDaysPrice) {
                        defaultValue = thisDaysPrice.amount ?? 0
                    }
                }
                setFakePeriodRateConfig({
                    amount: defaultValue,
                    departureDate: toDate,
                    arrivalDay: selectedRatePlan.fakePeriodRate.arrivalDay!,
                    departureDay: selectedRatePlan.fakePeriodRate.departureDay,
                    stayLength: selectedRatePlan.fakePeriodRate.stayLength
                })

            } else {
                setFakePeriodRateConfig(undefined)
            }
            setSelectedRatePlansForUpdate([ratePlanId!])
            setSelectedFromDate(selectedDate?.toLocaleDateString(languageCulture))
            let dayIndex = selectedDate?.getDay()
            if (dayIndex !== undefined) {
                setSelectedDays([dayIndex])
            }

            let newState: RatePlanAmount[] = RatePlans.map(rp => {
                let foundPrices = prices.find(x => x.ratePlanId === rp.ratePlanId)

                return {
                    ratePlanId: rp.ratePlanId,
                    unitPriceAmount: rp.priceStructure !== "PerUnit" ? undefined : foundPrices?.ratePlanInfo?.[0]?.ratePlanItems?.find(x => dateTimeFormatter.format(new Date(x.date!)) === dateTimeFormatter.format(selectedDate))?.amount as unknown as number,
                    perGuestAmounts: rp.priceStructure === "PerUnit" ? [] : foundPrices!.ratePlanInfo?.filter(p => p.ratePlanItems?.find(x => dateTimeFormatter.format(new Date(x.date!)) === dateTimeFormatter.format(selectedDate))?.amount !== null).map(p => {
                        return {
                            nrOfGuests: p.numberOfAdults,
                            ageGroupId: p.ageGroup?.ageGroupId,
                            amount: p.ratePlanItems?.find(x => dateTimeFormatter.format(new Date(x.date!)) === dateTimeFormatter.format(selectedDate))?.amount as unknown as number
                        }
                    })
                }
            })

            newState = newState.filter(x => !(x.perGuestAmounts?.length === 0 && !x.unitPriceAmount))

            setRatePlanAmounts(newState!)
        }
    }, [show, selectedDate, RatePlans, prices, ratePlanId])

    const labelStyle = {
        justifySelf: "end",
        alignSelf: "center",
        margin: 0
    }

    const checkboxStyle = {
        alignSelf: "center",
        marginLeft: "5px",
        display: "block"
    }

    const SelectedDaysChanged = (index: number) => {
        if (selectedDays.includes(index)) {
            setSelectedDays(s => s.filter(x => x !== index))
        } else {
            setSelectedDays(s => [
                ...s,
                index
            ])
        }
    }

    const UpdateRatePlanUnitPriceState = (ratePlanId: string, amount: number) => {
        let newRatePlanAmountState = [...ratePlanAmounts.filter(x => x.ratePlanId !== ratePlanId)]
        let newRatePlanAmount: RatePlanAmount = {
            ratePlanId: ratePlanId,
            unitPriceAmount: amount,
            perGuestAmounts: []
        }
        newRatePlanAmountState.push(newRatePlanAmount)
        setRatePlanAmounts(newRatePlanAmountState)
    }

    const UpdateRatePlanPerAdultPriceState = (ratePlanId: string, numberOfGuests: number | undefined, amount: number, ageGroupId: string | undefined) => {
        let stateCopy = [...ratePlanAmounts]

        let exisitingStateForRatePlan = stateCopy.find(x => x.ratePlanId === ratePlanId)
        let newPerGuestAmount: GuestAmount = {
            amount: amount,
            nrOfGuests: numberOfGuests,
            ageGroupId: ageGroupId
        }

        if (exisitingStateForRatePlan === undefined) {

            let newRatePlanAmount: RatePlanAmount = {
                ratePlanId: ratePlanId,
                unitPriceAmount: undefined,
                perGuestAmounts: [newPerGuestAmount]
            }
            stateCopy.push(newRatePlanAmount)
        } else {
            let existingPerPersonForThisRatePlan = exisitingStateForRatePlan?.perGuestAmounts?.find(x => x.nrOfGuests === numberOfGuests && x.ageGroupId === ageGroupId)

            if (existingPerPersonForThisRatePlan === undefined) {
                exisitingStateForRatePlan.perGuestAmounts?.push(newPerGuestAmount)
            } else {
                existingPerPersonForThisRatePlan.amount = amount
            }
        }
        setRatePlanAmounts(stateCopy)
    }

    let isAssumeMode = isBVUser

    let renderHeader = () => {
        if (fakePeriodRateConfig !== undefined) {
            let toDate = new Date(selectedDate!.getFullYear(), selectedDate!.getMonth(), selectedDate!.getDate() + fakePeriodRateConfig?.stayLength!)
            return `${translations.rates?.editRates} (${selectedDate?.toLocaleDateString(languageCulture)} ➜ ${toDate.toLocaleDateString(languageCulture)})`
        } else {
            return `${translations.rates?.editRates} (${selectedDate?.toLocaleDateString(languageCulture)})`
        }
    }

    function RenderSpecialRatePlanContent(rp: RateModel) {

        const PricePerNightInput = () => {
            return <Fragment key={rp.ratePlanId}>
                <hr/>
                <div key={rp.ratePlanId} style={{
                    display: "grid",
                    gridAutoFlow: "column",
                    gridTemplateColumns: "2fr 1fr",
                    marginBottom: "10px",
                    alignItems: "center",
                    justifyItems: "end"
                }}>
                    <p style={{
                        fontSize: "1.1rem",
                        margin: 0,
                        fontWeight: "bold",
                        marginLeft: "10px",
                        justifySelf: "start"
                    }}>
                        {translations.rates?.pricePerNight}
                    </p>
                    <input type="number" min={0} style={{height: "26px", width: "100px"}} max={999999}
                           value={fakePeriodRateConfig?.amount} onChange={(e) => {
                        let newValue = e.target.value as unknown as number

                        setFakePeriodRateConfig(currState => {
                            if (currState !== undefined) {
                                return {
                                    ...currState,
                                    amount: newValue > 999999 ? 999999 : newValue
                                }
                            }
                        })
                    }}/>
                    <p style={{margin: 0, marginLeft: "5px"}}>{rp.currencyCode}</p>
                </div>
            </Fragment>
        }

        const PricePerXNightsInput = () => {
            return <Fragment key={`${rp.ratePlanId}_2`}>
                <hr/>
                <div key={rp.ratePlanId} style={{
                    display: "grid",
                    gridAutoFlow: "column",
                    gridTemplateColumns: "2fr 1fr",
                    marginBottom: "10px",
                    alignItems: "center",
                    justifyItems: "end"
                }}>

                    <p style={{
                        fontSize: "1.1rem",
                        margin: 0,
                        fontWeight: "bold",
                        marginLeft: "10px",
                        justifySelf: "start"
                    }}>
                        {rp.fakePeriodRate?.stayLength && translations.rates?.pricePerXNights?.replace("{0}", rp.fakePeriodRate.stayLength.toString())}
                    </p>
                    <input disabled type="number" min={0} style={{height: "26px", width: "100px"}}
                           value={fakePeriodRateConfig?.amount! * rp.fakePeriodRate?.stayLength!}/>
                    <p style={{margin: 0, marginLeft: "5px"}}>{rp.currencyCode}</p>
                </div>
            </Fragment>
        }

        return (
            <div style={{background: "#dcdcdc6e", padding: "20px", marginTop: "20px"}}>
                <h4 style={{marginBottom: "20px"}}>{translations.rates?.rateplan}: {rp.ratePlanName}</h4>
                {PricePerNightInput()}
                {PricePerXNightsInput()}
            </div>
        )
    }


    return (
        <Dialog open={show} fullScreen={isTabletOrMobile} disableEscapeKeyDown={true}
                onClose={() => onClose()}
                aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <h4 style={{
                fontFamily: "open sans,Arial,sans-serif",
                marginTop: "20px",
                marginLeft: "45px"
            }}>  {renderHeader()} </h4>
            <DialogContent style={{paddingBottom: "20px"}}>
                {fakePeriodRateConfig && RenderSpecialRatePlanContent(prices.find(x => x.ratePlanId === ratePlanId)!)}
                {!fakePeriodRateConfig && <>
                    <form id="editRateForm" style={{paddingLeft: "20px", paddingRight: "20px"}}>
                        <br/>
                        <p>{translations.availability?.from}:</p>
                        <input required className="month-picker" disabled style={{
                            background: "transparent", height: "40px", borderRadius: "3px",
                            border: "1px solid #cfcecc", paddingLeft: "1rem", paddingRight: "1rem", width: "100%"
                        }} type="date" id="start" name="fromdate" min={new Date().toLocaleDateString(languageCulture)}
                               defaultValue={selectedDate?.toLocaleDateString(languageCulture)}
                        />
                        <br/><br/>
                        <p>{translations.availability?.to}:</p>
                        <input
                            required className="month-picker"
                            style={{
                                background: "transparent", height: "40px", borderRadius: "3px",
                                border: "1px solid #cfcecc", paddingLeft: "1rem", paddingRight: "1rem", width: "100%"
                            }} type="date" id="end" name="todate"
                            max={getOneYearAheadString(new Date(`'${selectedFromDate}'`), languageCulture)}
                            min={new Date(`'${selectedFromDate}'`).toLocaleDateString(languageCulture)}
                            defaultValue={selectedFromDate ?? ""}
                        />
                        <br/>
                        <br/>
                        <div className="weekdays-grid">
                            <p
                                style={{
                                    whiteSpace: "nowrap",
                                    margin: 0,
                                    fontWeight: "bold",
                                    alignSelf: "center"
                                }}>
                                {translations.common?.weekdays}
                            </p>
                            <div className="weekdays-grid-item">
                                <label style={{...labelStyle, textTransform: "capitalize"}}
                                       htmlFor="monday">{translations.availability?.mondayThreeLetters}</label>
                                <input checked={selectedDays.includes(1)} onChange={() => SelectedDaysChanged(1)}
                                       style={checkboxStyle}
                                       id="monday" type="checkbox"/>
                            </div>
                            <div className="weekdays-grid-item">
                                <label style={{...labelStyle, textTransform: "capitalize"}}
                                       htmlFor="tuesday">{translations.availability?.tuesdayThreeLetters}</label>
                                <input checked={selectedDays.includes(2)} onChange={() => SelectedDaysChanged(2)}
                                       style={checkboxStyle}
                                       id="tuesday" type="checkbox"/>
                            </div>
                            <div className="weekdays-grid-item">
                                <label style={{...labelStyle, textTransform: "capitalize"}}
                                       htmlFor="wednesday">{translations.availability?.wednesdayThreeLetters}</label>
                                <input checked={selectedDays.includes(3)} onChange={() => SelectedDaysChanged(3)}
                                       style={checkboxStyle}
                                       id="wednesday" type="checkbox"/>
                            </div>
                            <div className="weekdays-grid-item">
                                <label style={{...labelStyle, textTransform: "capitalize"}}
                                       htmlFor="thursday">{translations.availability?.thursdayThreeLetters}</label>
                                <input checked={selectedDays.includes(4)} onChange={() => SelectedDaysChanged(4)}
                                       style={checkboxStyle}
                                       id="thursday" type="checkbox"/>
                            </div>
                            <div className="weekdays-grid-item">
                                <label style={{...labelStyle, textTransform: "capitalize"}}
                                       htmlFor="friday">{translations.availability?.fridayThreeLetters}</label>
                                <input checked={selectedDays.includes(5)} onChange={() => SelectedDaysChanged(5)}
                                       style={checkboxStyle}
                                       id="friday" type="checkbox"/>
                            </div>
                            <div className="weekdays-grid-item">
                                <label style={{...labelStyle, textTransform: "capitalize"}}
                                       htmlFor="saturday">{translations.availability?.saturdayThreeLetters}</label>
                                <input checked={selectedDays.includes(6)} onChange={() => SelectedDaysChanged(6)}
                                       style={checkboxStyle}
                                       id="saturday" type="checkbox"/>
                            </div>
                            <div className="weekdays-grid-item">
                                <label style={{...labelStyle, textTransform: "capitalize"}}
                                       htmlFor="sunday">{translations.availability?.sundayThreeLetters}</label>
                                <input checked={selectedDays.includes(0)} onChange={() => SelectedDaysChanged(0)}
                                       style={checkboxStyle}
                                       id="sunday" type="checkbox"/>
                            </div>
                        </div>
                    </form>
                    <div style={{background: "#dcdcdc6e", padding: "20px", marginTop: "20px"}}>
                        <h4 style={{marginBottom: "20px"}}>{translations.rates?.rateplans}</h4>
                        {show && [...RatePlans]
                            .sort((x, y) => x.ratePlanId === ratePlanId ? -1 : y.ratePlanId === ratePlanId ? 1 : 0)
                            .map(rp => {
                                let ratePlan = prices.find(x => x.ratePlanId === rp.ratePlanId)
                                if (rp.priceStructure === "PerUnit") {
                                    let defaultValue = undefined
                                    let ratePlanPrices = prices.find(p => p.ratePlanId === rp.ratePlanId)

                                    for (let priceInfo of ratePlanPrices!.ratePlanInfo!) {
                                        let thisDaysPrice = priceInfo.ratePlanItems?.find(x => dateTimeFormatter.format(new Date(x.date!)) === dateTimeFormatter.format(selectedDate))
                                        if (thisDaysPrice) {
                                            defaultValue = thisDaysPrice.amount ?? undefined
                                        }
                                    }

                                    return <Fragment key={rp.ratePlanId}>
                                        <hr/>
                                        <div key={rp.ratePlanId} style={{
                                            display: "grid",
                                            gridAutoFlow: "column",
                                            gridTemplateColumns: "15px 2fr 1fr",
                                            marginBottom: "10px",
                                            alignItems: "center",
                                            justifyItems: "end"
                                        }}>
                                            <input disabled={ratePlan?.fakePeriodRate !== undefined}
                                                   defaultChecked={ratePlanId === rp.ratePlanId} onChange={(e) => {
                                                if (!e.target.checked) {
                                                    setSelectedRatePlansForUpdate(x => [...x.filter(y => y !== rp.ratePlanId)])
                                                } else {
                                                    setSelectedRatePlansForUpdate(x => [...x, rp.ratePlanId])
                                                }
                                            }} type="checkbox" style={{placeSelf: "center"}}/>
                                            <p style={{
                                                fontSize: "1.1rem",
                                                margin: 0,
                                                fontWeight: "bold",
                                                marginLeft: "10px",
                                                justifySelf: "start"
                                            }}>{rp.ratePlanName}</p>
                                            <input type="number" disabled={ratePlan?.fakePeriodRate !== undefined}
                                                   min={0} max={999999}
                                                   style={{height: "26px", width: "100px"}} defaultValue={defaultValue}
                                                   onChange={(e: any) => {
                                                       let newValue = e.target.value > 999999 ? 999999 : e.target.value
                                                       UpdateRatePlanUnitPriceState(rp.ratePlanId, newValue)
                                                   }}/>
                                            <p style={{margin: 0, marginLeft: "5px"}}>{rp.currencyCode}</p>
                                        </div>
                                    </Fragment>
                                } else {

                                    let ratePlanPrices = prices.find(p => p.ratePlanId === rp.ratePlanId)
                                    let inputs: Array<{
                                        NumberOfAdults: number,
                                        Price: number | undefined,
                                        AgeGroup: RateAgeGroup | undefined
                                    }> = []
                                    ratePlanPrices?.ratePlanInfo?.forEach(price => {
                                        let thisDaysPrice = price.ratePlanItems?.find(x => dateTimeFormatter.format(new Date(x.date!)) === dateTimeFormatter.format(selectedDate))

                                        inputs.push({
                                            NumberOfAdults: price.numberOfAdults!,
                                            Price: thisDaysPrice?.amount!,
                                            AgeGroup: price.ageGroup
                                        })
                                    })

                                    return <Fragment key={rp.ratePlanId}>
                                        <hr/>
                                        <div key={rp.ratePlanId} style={{
                                            display: "grid",
                                            gap: "10px",
                                            gridAutoFlow: "row",
                                            gridTemplateColumns: "15px 2fr 1fr",
                                            marginBottom: "10px",
                                            alignItems: "center"
                                        }}>
                                            <input disabled={ratePlan?.fakePeriodRate !== undefined} onChange={(e) => {
                                                if (!e.target.checked) {
                                                    setSelectedRatePlansForUpdate(x => [...x.filter(y => y !== rp.ratePlanId)])
                                                } else {
                                                    setSelectedRatePlansForUpdate(x => [...x, rp.ratePlanId])
                                                }
                                            }} defaultChecked={ratePlanId === rp.ratePlanId} type="checkbox"
                                                   style={{placeSelf: "center"}}/>
                                            <p style={{
                                                fontSize: "1.1rem",
                                                margin: 0,
                                                fontWeight: "bold"
                                            }}>{rp.ratePlanName}</p>
                                            {inputs.map((i, index) => {

                                                let inputName = i.NumberOfAdults ? `${i.NumberOfAdults} ${i.NumberOfAdults === 1 ? translations.common?.guest : translations.common?.guests}` : i.AgeGroup ? i.AgeGroup.name : ""

                                                return <div
                                                    key={i.NumberOfAdults !== undefined ? i.NumberOfAdults : i.AgeGroup !== undefined ? i.AgeGroup.name : index}
                                                    style={{
                                                        gridColumn: 3,
                                                        display: "grid",
                                                        gridTemplateColumns: "1fr 1fr min-content",
                                                        alignItems: "center",
                                                        justifyItems: "end"
                                                    }}>


                                                    <p style={{
                                                        whiteSpace: "nowrap",
                                                        margin: 0,
                                                        marginRight: "10px"
                                                    }}>{inputName}</p>
                                                    <input disabled={ratePlan?.fakePeriodRate !== undefined} min={0}
                                                           max={999999} type="number"
                                                           style={{height: "26px", width: "100px"}}
                                                           defaultValue={i.Price}
                                                           onChange={(e: any) => {
                                                               let newValue = e.target.value as number > 999999 ? 999999 : e.target.value as number
                                                               UpdateRatePlanPerAdultPriceState(rp.ratePlanId, i.NumberOfAdults, newValue, i.AgeGroup?.ageGroupId)
                                                           }}/>
                                                    <p style={{margin: 0, marginLeft: "5px"}}>{rp.currencyCode}</p>
                                                </div>
                                            })}
                                        </div>
                                    </Fragment>
                                }
                            })
                        }
                    </div>
                </>}
            </DialogContent>
            <DialogActions style={{paddingLeft: "24px", paddingRight: "24px", paddingBottom: "15px"}}>
                <MantineButton disabled={loading} onClick={() => onClose()} variant="secondary"
                               style={{minWidth: "60px"}}>
                    {translations.common?.cancel}
                </MantineButton>
                <MantineButton
                    disabled={loading || isAssumeMode || selectedRatePlansForUpdate.length === 0 || selectedDays.length === 0}
                    onClick={fakePeriodRateConfig ? SaveSpecialChanges : SaveChanges}
                    style={{minWidth: "80px"}}>
                    {loading && <Loader size="sm" role="status" aria-hidden="true"
                                        style={{marginRight: "5px", verticalAlign: "middle"}}/>}
                    {loading && translations.common?.saving}
                    {(!loading && isAssumeMode && translations.common?.disabledInAssumeMode) || (!loading && translations.common?.save)}
                </MantineButton>
            </DialogActions>
        </Dialog>
    )
}

export default RateChangeModal