import React, {FC, useContext, useEffect, useMemo, useState} from "react"

// Hooks
import {useCreateNewBooking} from "../hooks/useCreateNewBooking"

// Components
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core"
import {Button as MantineButton, Loader} from "@mantine/core"
import {TranslationContext} from "../translationcontext"
import {UserContext} from "../usercontext"
import DatePicker from "react-datepicker"
import {useMediaQuery} from "react-responsive"

// Lib
import {getDaysBetweenDates} from "../helpers"
import {addDays} from "date-fns"

// CSS
import "react-datepicker/dist/react-datepicker.css"

// Types
import {NewBookingModalProps} from "./interfaces"

const NewBookingModal: FC<NewBookingModalProps> = (
    {
        selectedObject, selectedDate, nextArrivalDate,
        onClose, guestRoomId, show,
        onReservationFailed
    }) => {
    const translations = useContext(TranslationContext)
    const userContext = useContext(UserContext)

    const [firstName, setFirstName] = useState<string>()
    const [lastName, setLastName] = useState<string>()

    const [selectedFromDate, setSelectedFromDate] = useState<Date | undefined>()
    const [endDate, setEndDate] = useState<Date | undefined>(selectedDate)
    const [nrOfDays, setNrOfDays] = useState<number>(0)
    const [guestIsOwner, setGuestIsOwner] = useState(false)
    const [numberOfGuests, setNumberOfGuests] = useState<number | undefined>(undefined)
    const isTabletOrMobile = useMediaQuery({query: "(max-width: 500px)"})

    const {mutateAsync: createNewBooking, isLoading} = useCreateNewBooking()

    const months = useMemo(() => [
        translations.availability?.january,
        translations.availability?.february,
        translations.availability?.march,
        translations.availability?.april,
        translations.availability?.may,
        translations.availability?.june,
        translations.availability?.july,
        translations.availability?.august,
        translations.availability?.september,
        translations.availability?.october,
        translations.availability?.november,
        translations.availability?.december
    ], [translations])

    const days = useMemo(() => [
        translations.availability?.sundayThreeLetters,
        translations.availability?.mondayThreeLetters,
        translations.availability?.tuesdayThreeLetters,
        translations.availability?.wednesdayThreeLetters,
        translations.availability?.thursdayThreeLetters,
        translations.availability?.fridayThreeLetters,
        translations.availability?.saturdayThreeLetters
    ], [translations])

    const calendarLocale: Locale = {
        localize: {
            month: (n: any) => months[n],
            day: (n: any) => days[n],
            dayPeriod: () => " ",
            era: () => " ",
            ordinalNumber: () => " ",
            quarter: () => " "
        },
        formatLong: {
            date: () => " ",
            dateTime: () => " ",
            time: () => " "
        },
        options: {
            weekStartsOn: 1
        }
    }

    const hasValidNames = !!(firstName && lastName);
    const disableSubmit = !guestIsOwner && !hasValidNames;

    const SaveBooking = async () => {
        if (!selectedFromDate || !endDate) {
            return
        }

        const guestFirstname = guestIsOwner ? userContext.user?.firstName || "" : firstName!
        const guestLastname = guestIsOwner ? userContext.user?.lastName || "" : lastName!

        const result = await createNewBooking({
            selectedFromDate,
            endDate,
            guestFirstname,
            guestLastname,
            guestIsOwner,
            guestRoomId,
            numberOfGuests,
        })

        if (result) {
            onClose(result)
        } else {
            onReservationFailed()
        }
    }

    useEffect(() => {
        if (show) {
            setSelectedFromDate(selectedDate)
            setGuestIsOwner(false)
            setNrOfDays(0)
            setEndDate(addDays(selectedDate!, 1))
            setNumberOfGuests(1)
            setFirstName("")
            setLastName("")
        }
    }, [show, selectedDate])

    useEffect(() => {
        if (selectedFromDate && endDate) {
            let nrOfDays = getDaysBetweenDates(new Date(selectedFromDate), endDate).length
            setNrOfDays(nrOfDays)
        }
    }, [endDate, selectedFromDate])

    const updateGuestIsOwner = (guestIsOwner: boolean) => {
        setGuestIsOwner(guestIsOwner)
        if (guestIsOwner) {
            setNumberOfGuests(1); // Default to 1 guest if owner
        }
    }

    const renderNumberOfGuestOptions = () => {
        const items = []
        for (let i = selectedObject!.minOccupancy ?? 0; i <= selectedObject!.maxOccupancy!; i++) {
            items.push(<option key={i} value={i} label={i!.toString()}/>)
        }
        return items
    }

    return (
        <Dialog open={show}
                fullScreen={isTabletOrMobile}
                disableEscapeKeyDown={true}
                onClose={() => onClose()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{translations.availability?.newBooking}</DialogTitle>
            <DialogContent style={{paddingBottom: "20px"}}>
                <DialogContentText id="alert-dialog-description">
                    {translations.availability?.newReservationDescription}
                </DialogContentText>
                <form id="newbookingform">
                    <br/>
                    <p>{translations.availability?.from}:</p>
                    {selectedFromDate && (
                        <DatePicker
                            disabled
                            required
                            selected={new Date(selectedFromDate!)}
                            name="fromdate"
                            className="to-date-picker"
                            locale={calendarLocale}
                            dateFormat="yyyy-MM-dd"
                            minDate={new Date()}
                            onChange={(x) => setSelectedFromDate(x ?? undefined)}
                        />
                    )}

                    <br/><br/>
                    <p>{translations.availability?.to}:</p>

                    <DatePicker
                        selected={endDate} name="todate"
                        onChange={(date: Date) => setEndDate(date)} className="to-date-picker"
                        selectsEnd
                        locale={calendarLocale}
                        startDate={new Date(selectedDate!)}
                        endDate={endDate}
                        dateFormat="yyyy-MM-dd"
                        maxDate={new Date(nextArrivalDate!)}
                        minDate={new Date(addDays(selectedDate!, 1))}
                    />

                    <br/>
                    <br/>
                    <p style={{color: "rgba(0, 0, 0, 0.54)"}}>{nrOfDays > 0 && `${nrOfDays} ${nrOfDays === 1 ? translations.common?.night : translations.common?.nights}`}</p>

                    <>
                        <hr/>
                        <h3>{translations.common?.guest}</h3>
                        <br/>

                        <div style={{display: "grid", gridAutoFlow: isTabletOrMobile ? "row" : "column", gap: "20px"}}>
                            <div>
                                <label>{translations.common?.firstname}</label>
                                <input
                                    id="guestfirstname"
                                    placeholder={guestIsOwner ? userContext.user?.firstName : translations.common?.firstname}
                                    disabled={isLoading || guestIsOwner}
                                    required={!guestIsOwner}
                                    className="form-control form-control-lg"
                                    type="text"
                                    value={guestIsOwner ? userContext.user?.firstName : firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                />
                            </div>

                            <div style={{gridRow: isTabletOrMobile ? 2 : 1}}>
                                <label>{translations.common?.lastname}</label>
                                <input
                                    id="guestlastname"
                                    placeholder={guestIsOwner ? userContext.user?.lastName : translations.common?.lastname}
                                    disabled={isLoading || guestIsOwner}
                                    required={!guestIsOwner}
                                    className="form-control form-control-lg"
                                    type="text"
                                    value={guestIsOwner ? userContext.user?.lastName : lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                />
                            </div>

                            <div style={{gridRow: isTabletOrMobile ? 3 : 2, gridColumn: 1}}>
                                <label>{translations.common?.nrOfGuests}</label>
                                <select
                                    id="nrofguests"
                                    name="nrofguests"
                                    className="form-control form-control-lg"
                                    value={numberOfGuests}
                                    onChange={(e) => setNumberOfGuests(Number(e.target.value))}
                                >
                                    {selectedObject !== undefined && renderNumberOfGuestOptions()}
                                </select>
                            </div>

                            <div style={{
                                gridColumn: isTabletOrMobile ? 1 : 2,
                                gridRow: isTabletOrMobile ? 4 : 2,
                                display: "grid",
                                alignItems: "center",
                                justifyItems: "start"
                            }}>
                                <div>
                                    <label style={{marginBottom: "0.2rem"}}>{translations.common?.guestIsOwner}</label>
                                    <input
                                        disabled={isLoading}
                                        name="guestisowner"
                                        checked={guestIsOwner}
                                        onChange={(e) => updateGuestIsOwner(e.target.checked)}
                                        style={{verticalAlign: "middle", marginLeft: "10px"}}
                                        type="checkbox"
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                </form>
            </DialogContent>
            <DialogActions style={{paddingLeft: "24px", paddingRight: "24px", paddingBottom: "15px"}}>
                <MantineButton disabled={isLoading} onClick={() => onClose()} variant="secondary"
                               style={{minWidth: "60px"}}>
                    {translations.common?.cancel}
                </MantineButton>
                <MantineButton
                    onClick={SaveBooking}
                    disabled={isLoading || disableSubmit} style={{minWidth: "80px"}}>
                    {isLoading && <Loader size="sm" role="status" aria-hidden="true"
                                          style={{marginRight: "5px", verticalAlign: "middle"}}/>}
                    {isLoading && translations.common?.saving}
                    {!isLoading && translations.common?.save}
                </MantineButton>
            </DialogActions>
        </Dialog>
    )
}

export default NewBookingModal
