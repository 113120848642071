import React, { FC, useContext } from "react"

// Hooks
import { useContact } from "../hooks/useContact"

// Components
import { TranslationContext } from "../translationcontext"

export const Contact: FC = () => {
  const translations = useContext(TranslationContext)
  const { data: organizationContact } = useContact()

  return (
    <div className="bv-main">
      <div className="bv-inner">
        <h1>{translations.contact?.contact}</h1>
        {organizationContact?.organizationName != null && organizationContact.organizationName !== "" &&
          <div>{translations.contact?.organizationName}: {organizationContact.organizationName}</div>
        }
        {
          organizationContact?.contactEmail != null && organizationContact.contactEmail !== "" &&
          <div>{translations.contact?.email}: {organizationContact.contactEmail}</div>
        }
        {
          organizationContact?.address != null && organizationContact.address !== "" &&
          <div>{translations.contact?.address}: {organizationContact.address}</div>
        }
        {
          organizationContact?.organizationPhoneNumber != null && organizationContact.organizationPhoneNumber !== "" &&
          <div>{translations.contact?.phone}: {organizationContact.organizationPhoneNumber}</div>
        }
        {
          organizationContact?.organizationMobileNumber != null && organizationContact.organizationMobileNumber !== "" &&
          <div>{translations.contact?.mobile}: {organizationContact.organizationMobileNumber}</div>
        }

      </div>
    </div>
  )
}