import React, { FC, useContext, useEffect } from "react"

// Hooks
import { useDateTimeFormat } from "../hooks/useDateTimeFormat"
import { useReservationsAndRestrictions } from "../hooks/useReservationsAndRestrictions"
import { useIsCalendarLoading } from "../hooks/useIsCalendarLoading"
import { useDays } from "../hooks/useDaysInMonth"

// Components
import { ArrowLeft, ArrowRight, Ban, CalendarPlus } from "./icons"
import { Loader, Menu } from "@mantine/core"
import { TranslationContext } from "../translationcontext"
import { UserContext } from "../usercontext"

// Lib
import { dotNetToJSDate, getDaysBetweenDates, getMonthDate, getWeekNumber, monthNumberToString } from "../helpers"
import { isAfter, isBefore, isEqual, isSameDay, startOfDay } from "date-fns"

// Types
import { AvailabilityCalendarProps } from "./interfaces"
import { GuestRoomRestriction, OwnerPortalReservation } from "../generated_api"

export const AvailabilityCalendar: FC<AvailabilityCalendarProps> = (
  {
    ImageUrl, DataFetchFailed,
    bookingClicked, newBookingClicked, newBlockClicked,
    selectedObjectId, selectedMonth, onMonthChanged, removeBlockClicked
  }) => {
  const translations = useContext(TranslationContext)
  const { hasFullAccess, isBVUser, ownerPortalChannelSettings } = useContext(UserContext)

  const days = useDays(selectedMonth)

  const { data: reservationsAndRestrictions, isError, error } = useReservationsAndRestrictions({
    selectedMonth,
    selectedObjectId
  })

  const isLoading = useIsCalendarLoading()
  const { languageCulture, dateTimeFormatter } = useDateTimeFormat()

  useEffect(() => {
    if (isError && error instanceof Error) {
      DataFetchFailed(error.message, "#d23a3a")
    }
  }, [isLoading, isError, error, DataFetchFailed])

  const DateAvailableForBookingTile =
    ({
       day,
       stayOverBookingThisDay,
       arrivalBookingThisDay,
       arrivalTomorrow,
       daysTilDeparture,
       isDoubleBooked,
       allBookings,
       allRestrictions
     }:
     {
       day: Date,
       stayOverBookingThisDay: OwnerPortalReservation | undefined,
       arrivalBookingThisDay: OwnerPortalReservation | undefined,
       arrivalTomorrow: any,
       daysTilDeparture: number,
       isDoubleBooked: boolean,
       allBookings: OwnerPortalReservation[],
       allRestrictions: GuestRoomRestriction
     }): React.JSX.Element => {
      if (isBefore(startOfDay(day), startOfDay(new Date()))) {
        return <></>
      }
      let className = ""
      if (stayOverBookingThisDay === undefined && arrivalBookingThisDay === undefined) {
        className = "bv-booked bv--1 free-before-after"
      } else if (arrivalBookingThisDay === undefined && arrivalTomorrow === undefined && (stayOverBookingThisDay === undefined || daysTilDeparture === 0)) {
        className = "bv-booked bv--1 booked-before"
      } else if (arrivalBookingThisDay === undefined && (stayOverBookingThisDay === undefined || daysTilDeparture === 0)) {
        className = "bv-booked bv--1"
      }

      if (isDoubleBooked) {
        className = ""
      }

      if (className !== "") {


        const nextArrival =
          allBookings.slice()
            .filter(x => x.arrival! > day)
            .sort(
              (b, a) => b.arrival!.getTime() / 1000 - a.arrival!.getTime() / 1000
            )[0]
        let nextArrivalDate = nextArrival?.arrival
        let nextRestrictionDate = allRestrictions?.rateRestrictions?.find(w => (w.closed && !w.closedByOwner) && isAfter(new Date(w.date!), day))?.date
        console.log(nextRestrictionDate)
        if (nextArrivalDate === undefined && nextRestrictionDate !== undefined) {
          nextArrivalDate = nextRestrictionDate
        } else if (nextRestrictionDate !== undefined && new Date(nextRestrictionDate) < nextArrivalDate!) {
          nextArrivalDate = nextRestrictionDate
        }

        return (
          <>
            <Menu position="top" withinPortal>
              <Menu.Target key={`${day.toLocaleDateString(languageCulture)}_cbo`}>
                <div className={className}>
                  <div id="free-day">
                    <div className="bv-about">
                      <div className="bv-name" style={{ fontSize: "0.9em" }}></div>
                    </div>
                  </div>
                </div>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Item

                  disabled={isBVUser && !hasFullAccess}
                  style={{ cursor: "pointer", userSelect: "none", border: "none" }}
                  onClick={() => {
                    newBookingClicked(day, false, nextArrivalDate, nextRestrictionDate)
                  }} className="list-group-item">
                  <CalendarPlus />
                  <p style={{
                    display: "inline",
                    verticalAlign: "middle",
                    marginLeft: "5px"
                  }}>{translations.availability?.newBooking} {isBVUser && hasFullAccess && `(${translations.common?.disabledInAssumeMode})`}</p>
                </Menu.Item>

                <Menu.Divider />

                <Menu.Item

                  disabled={isBVUser && !hasFullAccess}
                  style={{ cursor: "pointer", userSelect: "none", border: "none" }}
                  onClick={() => {
                    newBlockClicked(day, true, nextArrivalDate, nextRestrictionDate)
                  }} className="list-group-item">
                  <Ban />
                  <p style={{
                    display: "inline",
                    verticalAlign: "middle",
                    marginLeft: "5px"
                  }}>{translations.availability?.newBlock} {isBVUser && !hasFullAccess && `(${translations.common?.disabledInAssumeMode})`}</p>
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </>
        )
      } else {
        return <></>
      }
    }


  const WeekdaysRow = (weekNumber: number, days: Date[], bookings: OwnerPortalReservation[]) => {
    return (
      <div key={weekNumber} className="bv-row bv--week-days">
        <div className="bv-week">
          <div>
            {weekNumber}
          </div>
        </div>
        {days.map((day, index) => {
          let restrictionsForThisRoom = reservationsAndRestrictions?.restrictions?.find(x => x.guestRoomId === selectedObjectId || x.roomUnitId === selectedObjectId)
          let restrictedThisDay = restrictionsForThisRoom?.rateRestrictions?.find(r =>
            isSameDay(dotNetToJSDate(r.date!), day) && r.closed === true
          )
          let arrivalBookingThisDay = bookings.find(x => isSameDay(x.arrival!, day))
          let stayOverBookingThisDay = bookings.find(x =>
            (isBefore(x.arrival!, day) || isSameDay(x.arrival!, day)) &&
            (isAfter(x.departure!, day) || isSameDay(x.departure!, day))
          )

          let stayLength = arrivalBookingThisDay !== undefined ? getDaysBetweenDates(arrivalBookingThisDay.arrival!, arrivalBookingThisDay.departure!).length : 0
          let arrivalClassName = `bv-booked bv--${stayLength > 7 ? "7" : stayLength} ${arrivalBookingThisDay?.channel === "OwnersPortal" ? "bv-calmarker-owner" : arrivalBookingThisDay?.channel === "blocked" ? `bv-calmarker-blocked` : "bv-calmarker-default"} clickable`
          let stayOverClassName = `bv-booked bv--0 ${stayOverBookingThisDay?.channel === "OwnersPortal" ? "bv-calmarker-owner" : stayOverBookingThisDay?.channel === "blocked" ? `bv-calmarker-blocked` : "bv-calmarker-default"} clickable`

          const nextArrival =
            reservationsAndRestrictions?.reservations?.slice()
              .filter(x => x.arrival! > day)
              .sort(
                (b, a) => b.arrival!.getTime() / 1000 - a.arrival!.getTime() / 1000
              )[0]

          let nextArrivalDate = nextArrival?.arrival
          let nextRestrictionDate = restrictionsForThisRoom?.rateRestrictions?.find(w => (w.closed && !w.closedByOwner) && new Date(w.date!) > day)?.date
          if (nextArrivalDate === undefined && nextRestrictionDate !== undefined) {
            nextArrivalDate = nextRestrictionDate
          } else if (nextRestrictionDate !== undefined && new Date(nextRestrictionDate) < nextArrivalDate!) {
            nextArrivalDate = nextRestrictionDate
          }

          let arrivalWasAnotherWeek = stayOverBookingThisDay !== undefined
            && (getWeekNumber(stayOverBookingThisDay.arrival!) < getWeekNumber(day)
              || getWeekNumber(stayOverBookingThisDay.arrival!) > getWeekNumber(day))

          let daysTilDeparture = stayOverBookingThisDay !== undefined ? getDaysBetweenDates(day, stayOverBookingThisDay.departure!).length : 0

          let tomorrowDate = new Date(day.getTime())
          tomorrowDate.setDate(tomorrowDate.getDate() + 1)
          let arrivalTomorrow = bookings.find(x => isEqual(startOfDay(x.arrival!), startOfDay(tomorrowDate)))

          let isDoubleBooked = bookings.filter(x =>
            isBefore(startOfDay(x.arrival!), startOfDay(day)) &&
            !isBefore(startOfDay(x.departure!), startOfDay(day))
          ).length > 1

          let isLeavingToday = stayOverBookingThisDay
            && isEqual(startOfDay(stayOverBookingThisDay.departure!), startOfDay(day))

          if (reservationsAndRestrictions?.allUnavailable || ((isLeavingToday || stayOverBookingThisDay === undefined) && arrivalBookingThisDay === undefined && restrictedThisDay && isAfter(day, new Date()) || isSameDay(day, new Date()))) {
            if (restrictedThisDay?.closedByOwner) {
              return (
                <>
                  <Menu position="top" withinPortal>
                    <Menu.Target key={`${day.toLocaleDateString(languageCulture)}_cbo`}>
                      <div className="bv-day bv-calmarker-notavail-owner">
                        <span className="bv-date">
                        {day.getDate()}
                        </span>
                      </div>
                    </Menu.Target>

                    <Menu.Dropdown>
                      <Menu.Item
                        disabled={(isBVUser && !hasFullAccess) || (ownerPortalChannelSettings?.ownersPortalForFrontdesk)}
                        style={{ cursor: "pointer", userSelect: "none" }}
                        onClick={() => newBookingClicked(day, false, nextArrivalDate, nextRestrictionDate)}>
                        <CalendarPlus />
                        <p style={{
                          display: "inline",
                          verticalAlign: "middle",
                          marginLeft: "5px"
                        }}>{translations.availability?.newBooking} {isBVUser && !hasFullAccess && `(${translations.common?.disabledInAssumeMode})`}</p>
                      </Menu.Item>

                      <Menu.Divider />

                      <Menu.Item
                        disabled={isBVUser && !hasFullAccess}
                        style={{ cursor: "pointer", userSelect: "none" }} onClick={() => {
                        removeBlockClicked(day, false, nextArrivalDate, nextRestrictionDate)
                      }}>
                        <Ban />
                        <p style={{
                          display: "inline",
                          verticalAlign: "middle",
                          marginLeft: "5px"
                        }}>{translations.availability?.removeBlock} {isBVUser && !hasFullAccess && `(${translations.common?.disabledInAssumeMode})`}</p>
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                </>
              )
            } else {
              return (
                <div key={`${day.toLocaleDateString(languageCulture)}_closed`}
                     style={{ userSelect: "none" }}
                     className={`bv-day bv-calmarker-notavail`}>
                                    <span className="bv-date">
                                        {day.getDate()}
                                    </span>
                </div>
              )
            }


          }

          let cName = "bv-day"
          if (isBefore(startOfDay(day), startOfDay(new Date()))) {
            cName = "bv-day bv-day-past"
          }

          return (
            <React.Fragment key={`${dateTimeFormatter.format(day)}_${index}`}>
              <div style={{ userSelect: "none" }} className={cName}>
                {arrivalWasAnotherWeek && index === 0 &&
                  <div onClick={() => bookingClicked(stayOverBookingThisDay!)}
                       id={stayOverBookingThisDay!.bookingVersionPublicId}
                       className={stayOverClassName}
                       style={{ width: `${40 + (100 * daysTilDeparture)}%` }}>
                    <div>
                      {daysTilDeparture > 0 && <div className="bv-about">
                        <div className="bv-channel"
                             style={{ backgroundImage: `url(${stayOverBookingThisDay!.channelLogoImageUrl || `${ImageUrl}${stayOverBookingThisDay!.channelLogoImageId}.png`})` }}></div>
                        <div
                          className="bv-name">{stayOverBookingThisDay?.isCustomerDataHidden ? stayOverBookingThisDay.bookingCode : stayOverBookingThisDay!.customerName}</div>
                      </div>}
                    </div>
                  </div>}
                {arrivalBookingThisDay !== undefined &&
                  <div key={arrivalBookingThisDay!.bookingVersionPublicId}
                       onClick={() => bookingClicked(arrivalBookingThisDay!)}
                       className={arrivalClassName}
                       id={arrivalBookingThisDay!.bookingVersionPublicId}>
                    <div>
                      <div className="bv-about">
                        {arrivalBookingThisDay.channel !== "blocked" &&
                          <div className="bv-channel"
                               style={{ backgroundImage: `url(${arrivalBookingThisDay!.channelLogoImageUrl || `${ImageUrl}${arrivalBookingThisDay!.channelLogoImageId}.png`})` }}></div>}
                        <div
                          className="bv-name">{arrivalBookingThisDay?.isCustomerDataHidden ? arrivalBookingThisDay.bookingCode : arrivalBookingThisDay!.customerName}</div>
                      </div>
                    </div>

                  </div>}
                {!isLoading && <DateAvailableForBookingTile
                  isDoubleBooked={isDoubleBooked}
                  arrivalBookingThisDay={arrivalBookingThisDay}
                  arrivalTomorrow={arrivalTomorrow} day={day}
                  daysTilDeparture={daysTilDeparture}
                  stayOverBookingThisDay={stayOverBookingThisDay}
                  allBookings={bookings}
                  allRestrictions={restrictionsForThisRoom!} />}
                <span className="bv-date">
                                    {day.getDate()}
                                </span>
              </div>
            </React.Fragment>
          )
        })}
      </div>
    )
  }


  const renderWeekRows = () => {
    if (days.thisMonth.length === 0) {
      return
    }

    let daysArr = [...days.thisMonth]
    let daysLastMonthArr = [...days.lastMonth]
    let daysNextMonthArr = [...days.nextMonth]
    let res = []

    while (daysArr[0].getDay() !== 1) {
      let lastDayLastMonth = daysLastMonthArr.pop()
      daysArr.unshift(lastDayLastMonth!)
    }

    let lastWeekThisMonth = getWeekNumber(days.thisMonth.slice(-1)[0])

    let nrOfDaysLastWeekInMonth = daysArr.filter(x => getWeekNumber(x) === lastWeekThisMonth)

    let missingDays = 7 - nrOfDaysLastWeekInMonth.length

    for (let i = 0; i < missingDays; i++) {
      daysArr.push(daysNextMonthArr.shift()!)
    }

    while (daysArr.length > 0) {
      let weekNr = getWeekNumber(daysArr[0])
      res.push(WeekdaysRow(weekNr, daysArr.splice(0, 7), reservationsAndRestrictions?.reservations ?? []))
    }
    return res
  }

  const DayNameHeader = () => {
    return (

      <div className="bv-row bv--week-name">
        <div className="bv-week">
          <span className="bv-long">{translations.availability?.week}</span>
          <span className="bv-short">{translations.availability?.week}</span>
        </div>
        <div className="bv-day">
          <span className="bv-long">{translations.availability?.monday}</span>
          <span className="bv-short">{translations.availability?.mondayThreeLetters}</span>
        </div>
        <div className="bv-day">
          <span className="bv-long">{translations.availability?.tuesday}</span>
          <span className="bv-short">{translations.availability?.tuesdayThreeLetters}</span>
        </div>
        <div className="bv-day">
          <span className="bv-long">{translations.availability?.wednesday}</span>
          <span className="bv-short">{translations.availability?.wednesdayThreeLetters}</span>
        </div>
        <div className="bv-day">
          <span className="bv-long">{translations.availability?.thursday}</span>
          <span className="bv-short">{translations.availability?.thursdayThreeLetters}</span>
        </div>
        <div className="bv-day">
          <span className="bv-long">{translations.availability?.friday}</span>
          <span className="bv-short">{translations.availability?.fridayThreeLetters}</span>
        </div>
        <div className="bv-day">
          <span className="bv-long">{translations.availability?.saturday}</span>
          <span className="bv-short">{translations.availability?.saturdayThreeLetters}</span>
        </div>
        <div className="bv-day">
          <span className="bv-long">{translations.availability?.sunday}</span>
          <span className="bv-short">{translations.availability?.sundayThreeLetters}</span>
        </div>
      </div>
    )
  }

  const GoBackOneMonth = () => {

    let currentDate = getMonthDate(selectedMonth)

    let year = currentDate.getFullYear()
    let monthNr = currentDate.getMonth()
    if (monthNr < 1) {
      monthNr = 12
      year -= 1
    }

    onMonthChanged(`${year}-${monthNr.toString().padStart(2, "0")}`)
  }

  const GoForwardOneMonth = () => {

    let currentDate = getMonthDate(selectedMonth)
    let year = currentDate.getFullYear()
    let monthNr = currentDate.getMonth() + 2
    if (monthNr > 12) {
      monthNr = 1
      year += 1
    }

    onMonthChanged(`${year}-${monthNr.toString().padStart(2, "0")}`)
  }

  return (
    <div>
      <div className="vg-panel"
           style={{ opacity: isLoading ? "0.5" : "1", userSelect: "none" }}
      >
        <div className="vg-panel-header">


          <div className="vg-panel-title">
            <h3>
              <span>{monthNumberToString(getMonthDate(selectedMonth).getMonth(), translations)}</span>
              {" "}
              <span>{getMonthDate(selectedMonth).getFullYear()}</span>
            </h3>
          </div>
          <div className="vg-actions">
            {isLoading &&
              <Loader style={{ verticalAlign: "middle", marginRight: "10px" }} />}

            <button disabled={isLoading} onClick={GoBackOneMonth} className="btn btn-secondary"
                    type="button">
                            <span className="vg-icon vg-icon-only">
                                <ArrowLeft />
                            </span>
            </button>

            <button disabled={isLoading} onClick={GoForwardOneMonth} className="btn btn-secondary"
                    type="button">
                            <span className="vg-icon vg-icon-only">
                                <ArrowRight />
                            </span>
            </button>
          </div>
        </div>

        <div className="bv-avail-calendar">
          <div className="bv-inner">
            <DayNameHeader />
            {renderWeekRows()}
          </div>
        </div>
      </div>

      <div className="bv-legends">
        <div className="bv-legend">
          <div className="bv-calmarker-default">
            <div></div>
          </div>
          <div>{translations.availability?.booked}</div>
        </div>
        <div className="bv-legend">
          <div className="bv-calmarker-owner">
            <div></div>
          </div>
          <div>{translations.availability?.yourBooking}</div>
        </div>
        <div className="bv-legend">
          <div className="bv-calmarker-notavail">
            <div></div>
          </div>
          <div>{translations.availability?.notAvailForBooking}</div>
        </div>
        <div className="bv-legend">
          <div className="bv-calmarker-past-date">
            <div></div>
          </div>
          <div>{translations.availability?.pastDate}</div>
        </div>
        <div className="bv-legend">
          <div className="bv-calmarker-notavail-owner">
            <div></div>
          </div>
          <div>{translations.common?.ownersBlock}</div>
        </div>
      </div>
    </div>
  )
}