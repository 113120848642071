import React, { FC, useContext } from "react"

// Components
import { TranslationContext } from "../translationcontext"

// Types
import { AccountInfoPhoneFormGroupProps } from "./interfaces"


export const AccountInfoPhoneFormGroup: FC<AccountInfoPhoneFormGroupProps> = (
  {
    updateAccountInfo, loading, phone
  }) => {
  let translations = useContext(TranslationContext)

  const handleChange = (event: any) => {
    updateAccountInfo("phone", {
      ...phone,
      [event.currentTarget.name]: event.currentTarget.value
    })
  }

  return (
    <div className="vg-form-group">
      <label>{translations.contact?.phone}</label>
      <div style={{ display: "flex" }}>
        <label style={{ padding: "0.75em 0em 0em" }}>+</label>
        <input
          className="form-control form-control-lg"
          disabled={loading}
          type="tel"
          name="countryCode"
          placeholder="1"
          onBlur={handleChange}
          defaultValue={phone.countryCode}
          style={{ width: "5em", marginLeft: "0.25em" }}
        />
        <input
          className="form-control form-control-lg"
          disabled={loading}
          type="tel"
          name="number"
          placeholder="0123456789"
          onBlur={handleChange}
          defaultValue={phone.number}
          style={{ marginLeft: "0.25em" }}
        />
      </div>
    </div>
  )
}
