import { useQuery } from "@tanstack/react-query"
import * as API from "../api"

export const useContact = () => {
  return useQuery(["contact"], async () => {
    return await API.GetOrganizationContactInformation()
  }, {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    suspense: true,
  })
}