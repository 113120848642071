import React from "react"
import { LogError } from "../api"
import { ErrorMessage } from "../models/error_message"

export interface ErrorBoundaryProps {
  children: React.ReactNode
}

const LogMessage = async (msg: ErrorMessage) => {
  await LogError(
    {
      message: msg.message,
      url: msg.url
    }
  )
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  public state = {
    hasError: false,
    errorMessage: ""
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, errorMessage: error.message }
  }

  componentDidCatch(error: Error, errorInfo: any) {
    LogMessage(
      {
        message: error.stack?.toString() ?? "Unknown error",
        url: window.location.href
      }
    )
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ padding: "2rem", textAlign: "center" }}>
          <h1>Something went wrong.</h1>
          <h2>{this.state.errorMessage}</h2>
          <button onClick={() => window.location.reload()} style={{ marginTop: "2rem" }}>Reload page</button>
        </div>
      )
    }

    return this.props.children
  }
}